import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, Image, Button, CardHeader, Modal, ModalContent, ModalHeader, ModalBody, useDisclosure, ModalFooter } from "@nextui-org/react";
import { ShopContext } from "../../context/ShopContextProvider";
import { Accordion, AccordionItem } from "@nextui-org/react";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ImagesCarousel from "./ImagesCarousel";
import { ButikAPI } from "../../apis/ButikAPI";
import Slider from "./Slider";
import Login from "../Login/Login";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlined from "@mui/icons-material/ArrowBackIosNewOutlined";
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { PropaneRounded } from "@mui/icons-material";

function GameCard(props) {
    const { addToCart, userToken, isLoggedIn, setShowLoading } = React.useContext(ShopContext);
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [isActiveGame, setIsActiveGame] = useState(false);
    const [headerText, setHeaderText] = useState('');
    const [displayModal, setDisplayModal] = useState(false);

    const handleFavorite = () => {
        if (isLoggedIn) {
            ButikAPI.updateUserProduct(userToken, props.game.id, props.game.isBought, !props.game.isFavorite);
            props.updateGame(props.game);
        }
        else
            setDisplayModal(true);
    };

    const windowWidth = {
        width: window.innerWidth,
    };

    useEffect(() => {
        if(props.game.price === 0 && props.game.video !== null && props.game.video !== undefined && props.game.video !== ""){
            setIsActiveGame(true);
            setHeaderText(props.game.name);
        }
        else{
            setIsActiveGame(false);
            setHeaderText('Du måste logga in för att spara en lek som favorit');
        }
    }, [props.game])

    const handleOnClick = () =>{
        if(isActiveGame){
            setDisplayModal(true);
            setShowLoading(true);
        }
        else
            addToCart(props.game);
    }

    const handleIframeLoad = () => {
        setShowLoading(false);
    }

    return (
        <>
        <Card isBlurred shadow="lg" >
            <CardHeader className="absolute z-20 justify-between">
                <div></div>
                <Button className="float-right" radius="full"
                    isIconOnly variant="faded"
                    color="danger"
                    aria-label="Favorite"
                    onClick={handleFavorite}>
                    {props.game.isFavorite ?
                        <FavoriteIcon /> :
                        <FavoriteBorderIcon />}
                </Button>
            </CardHeader>
            <CardBody className="overflow-hidden p-0">
                {/* <Image
                    shadow="sm"
                    radius="lg"
                    width="100%"
                    alt={props.game.name}
                    className="w-full object-cover h-[240px]"
                    src={URL_MEDIA + props.game.id + "/" + props.game.titleImage}
                /> */}
                <Slider images={props.game.images} gameId={props.game.id}/>

                <div className="shadow-inner">
                    <Accordion >
                        <AccordionItem
                            aria-label="Accordion 1"
                            title={props.game.shopDescription}
                            data-open="true"
                            indicator={({isOpen}) => (isOpen ?  <ArrowBackIosNewOutlined /> :  <ArrowForwardIosOutlinedIcon />)}>
                            {props.game.description}
                        </AccordionItem>
                    </Accordion>
                </div>
            </CardBody>

            <CardFooter className="text-small justify-between">

                <h4 className="font-medium text-large">{props.game.price}:-</h4>
                <Button radius="full"
                    isIconOnly variant="faded"
                    color="danger"
                    aria-label="Buy"
                    onClick={() => handleOnClick()}>
                        {isActiveGame ?
                            <PlayArrowOutlinedIcon />
                            :
                            <ShoppingBagOutlinedIcon />
                        }
                </Button>

            </CardFooter>
        </Card>

        {displayModal ?
            <div style={{zIndex: 90}} className="flex flex-col inset-0 fixed bg-gray-50 flex justify-center items-center">
                <div className="h-full overflow-y-auto pt-10 pb-32 jusify-items-center">
                <span style={{color: "#504459"}} className="p-5 text-xl font-semibold">{headerText}</span>
                {isActiveGame ?
                    <iframe
                        width="100%"
                        height="80%"
                        className="p-5"
                        src={props.game.video}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        onLoad={handleIframeLoad}
                    />
                    :
                    <Login doAction={() => setDisplayModal(false)}/>
                }
                <div className="pb-10 w-full p-5">
                    <Button style={{backgroundColor: "#504459"}}
                        className="h-14 w-full text-xl text-white shadow-lg"
                        variant="flat"
                        color="danger"
                        aria-label="Buy"
                        onPress={() => setDisplayModal(false)}>
                            Stäng
                    </Button>
                </div>
                </div>
            </div>
        : ""}

    </>
    )
}

export default GameCard;
