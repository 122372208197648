import React from "react";
import HeaderComponent from "../Search/HeaderComponent";
import { SocialIcon } from "react-social-icons";
import 'react-social-icons/instagram';

function About() {

    return(
        <div className="container h-screen">
            <HeaderComponent header="Om appen" goBackScreen="/myaccount"/>
            <div className="fixed inset-x-2.5 top-20">
                <div style={{color: "504459"}} className="rounded-2xl shadow-lg p-2 bg-white bg-opacity-40 pb-20">
                    <div className="p-5 pt-10">
                        <p className="pb-5">Med Pimparty har du tillgång till roliga tävlingar/lekar direkt i din mobil.
                        Pimparty är det optimala mobilspelet för dig som på ett smidigt och enkelt sätt vill leka lekar på olika fester och tillställningar. Appen kan man använda som både lekledare och som gäst. Appen är ett verktyg som ersätter behovet av papper och penna på fester för att göra olika tävlingar/lekar/quizar.</p>
                        <p className="pb-5">Helt enkelt - Party i mobilen!!</p>
                    </div>


                    <div className="pt-10">
                        <p className="pb-5">Följ oss på Instagram:</p>
                        <SocialIcon url="https://www.instagram.com/festligatips"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
