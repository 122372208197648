import React,  { useContext, useEffect, useCallback, useState} from "react";
import { GameDataList } from "../../api/GameData";
import MyGamesList from "./MyGames/MyGamesList";
import { ShopContext } from "../../context/ShopContextProvider";
import HeaderComponent from "../Search/HeaderComponent";
import { ButikAPI } from "../../apis/ButikAPI";
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner, useDisclosure } from "@nextui-org/react";

function MyGames(props) {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [newGroupName, setNewGroupName] = useState();
    const {userGames, setUserGames, setShowLoading, isLoggedIn, appUser, userToken} = useContext(ShopContext);

    const fetchGames = useCallback(() => {
        setShowLoading(true);
        return ButikAPI.getGameGroupsForUser(userToken, appUser.id_user).then(r => r.json())
            .then(d => {
                setShowLoading(false);
                if (d.status === "success") {
                    setUserGames(d.gamegroups);//d.gamegroups.map((g) => addToUserGames(g));
                }
                else {
                    console.log(d);
                }
            });
  }, [])

    const saveNewGroup = useCallback(() => {
        setShowLoading(true);
        return ButikAPI.saveNewGameGroup(userToken, appUser.id_user, newGroupName)
            .then(r => r.json())
            .then(d => {
                setShowLoading(false);
                if (d.status === "success") {
                    setUserGames(d.gamegroups);
                }
                else {
                    console.log(d);
                }
            });
    })

    const addGameToGroup = useCallback((groupid, gameid) => {
        setShowLoading(true);
        return ButikAPI.addToGameGroup(userToken, appUser.id_user, groupid, gameid)
            .then(r => r.json())
            .then(d => {
                setShowLoading(false);
                if (d.status === "success") {
                    setUserGames(d.gamegroups);
                }
                else {
                    console.log(d);
                }
            });
    })

    useEffect(() => {
        if(isLoggedIn && (userGames?.length === 0 || userGames === null))
            //console.log(appUser);
            fetchGames();
    }, [isLoggedIn, userGames, fetchGames]);

    const createNewGroup = () => {
        //open modal
        onOpen();
    }

    const goToShop = () => {
        // go to shop
        if(props.goToShop !== null && props.goToShop !== undefined){
            props.goToShop({name: "Home", route: "/pim", position: 0});
        }
    }
    const savegroup = () =>{
        //save new group
        onOpenChange();
        console.log(newGroupName);
        saveNewGroup();
    }
    const deleteGameFromGroup = useCallback((groupid, gameid) => {
        setShowLoading(true);
        return ButikAPI.deleteFormGameGroup(userToken, appUser.id_user, groupid, gameid)
            .then(r => r.json())
            .then(d => {
                setShowLoading(false);
                if (d.status === "success") {
                    setUserGames(d.gamegroups);
                }
                else {
                    console.log(d);
                }
            });
    })

    const deleteGroup = (groupid) => {
        setShowLoading(true);
        return ButikAPI.deleteGameGroup(userToken, appUser.id_user, groupid)
            .then(r => r.json())
            .then(d => {
                setShowLoading(false);
                if (d.status === "success") {
                    setUserGames(d.gamegroups);
                }
                else {
                    console.log(d);
                }
            });

    }

    return(
        <div className="h-screen">
            <HeaderComponent header="Mina lekar" goBackScreen="/myaccount"/>

            <div className="fixed inset-4 top-20 bottom-36">
                {userGames?.length > 0 ?
                        <div style={{height: "100%"}} className="flex flex-col">
                            <MyGamesList
                                gameGroups={userGames}
                                action={addGameToGroup}
                                delete={deleteGameFromGroup}
                                deleteGroup={deleteGroup}/>
                        </div>
                    :
                    ""
                }

                {
                    userGames?.length > 0 ?
                    <div className="fixed bottom-10 w-full left-0">
                        <div className="grid grid-cols-1 pb-12 p-5">
                            <Button style={{backgroundColor: "#504459"}}
                                className="h-14 text-xl text-white"
                                variant="flat"
                                aria-label="create"
                                onPress={createNewGroup}>
                                        Skapa ny grupp
                            </Button>
                        </div>
                    </div> :
                    <div className="flex justify-center">
                    <span style={{color: "#504459"}} className="pt-10 text-xl font-semibold">
                        Du har inga lekar.
                    </span>
                    <div className="fixed bottom-10 w-full left-0 p-5 pb-12">
                        <div className="grid grid-cols-1">
                            <Button style={{backgroundColor: "#504459"}}
                                className="h-14 text-xl text-white"
                                variant="flat"
                                onPress={goToShop}>Gå till butiken</Button>
                        </div>
                    </div>
                    </div>
                }
            </div>
            <Modal
                placement="center"
                isOpen={isOpen}
                onOpenChange={onOpenChange}>
                <ModalContent>
                {(onClose) => (
                    <>
                    <ModalHeader className="flex flex-col gap-1">
                        Ny grupp
                    </ModalHeader>
                    <ModalBody>
                        <Input isRequired
                            label="Namn"
                            type="text"
                            value={newGroupName}
                            onValueChange={setNewGroupName}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={onClose}>
                            Avbryt
                        </Button>
                        <Button variant="light" onPress={savegroup}>
                            Spara
                        </Button>
                    </ModalFooter>
                    </>
                )}
                </ModalContent>
            </Modal>
        </div>
    );
}

export default MyGames;
