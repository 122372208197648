import React from "react";
import GameCard from "./GameCard.js";

const windowWidth = {
    width: window.innerWidth,
  };


function GameList(props) {
    return(
        <div className="p-2 px-4 pb-32 grid grid-col-1 gap-3 h-full overflow-y-auto">
            <div className="grid place-content-center gap-5 pb-5">
                {props.games?.map((game) =>
                <GameCard game={game} key={game.id} updateGame={props.updateGame}/>
                )}
            </div>
        </div>
    )
}

export default GameList;
