import { SubscriptionAPI } from "../apis/SubscriptionAPI";

function urlBase64ToUint8Array(base64String: string): Uint8Array {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

type SubEvent = {
    isSubscribed: boolean;
    subscription: PushSubscription;
};
type SubEventCallback = (evt: SubEvent) => void;
let changeListeners: SubEventCallback[] = [];

export const onSubscriptionChange = (callback: SubEventCallback) => {
    changeListeners.push(callback);
};
export const offSubscriptionChange = (callback: SubEventCallback) => {
    changeListeners = changeListeners.filter((cb) => cb !== callback);
};
const triggerOnChange = (evt: SubEvent) => {
    changeListeners.forEach((cb) => {
        try {
            cb(evt);
        } catch {}
    });
};

export const registerAndAskForPushSubscription = async (): Promise<boolean> => {
    try {
        const sub = await getPushSubscription();

        if (sub) {
            return true;
        }
    } catch {}

    try {
        const sub = await askForSubscription();

        if (!sub) {
            // User rejected the permission request
            return false;
        }

        await registerSubscription(sub);

        return true;
    } catch {}
    return false;
};

export const getPushSubscription =
    async (): Promise<PushSubscription | null> => {
        const reg = await navigator.serviceWorker.ready;
        if (!reg) {
            // this shouldn't happen
            throw new Error("Service worker not ready");
        }

        const sub = await reg.pushManager.getSubscription();
        if (sub === null) {
            return null;
        }

        return sub;
    };

/**
 * Associates the current push subscription with the given user, allowing for per-user push notifications.
 */
export const associateUser = async (userId: number): Promise<boolean> => {
    const subscription = await getPushSubscription();
    if (subscription === null) {
        throw new Error("Could not get push subscription");
    }

    const result = await SubscriptionAPI.associateUser(subscription, userId);
    const json = await result.json();
    if (!json || json.status !== "success") {
        throw new Error("Could not register push subscription");
    }
    return true;
};

export const registerSubscription = async (
    sub: PushSubscription
): Promise<boolean> => {
    if (sub === null) {
        throw new Error("Could not subscribe to push notifications");
    }

    // It is fine to register the subscription even if it exists, it will just be a noop returning success.
    const result = await SubscriptionAPI.register(sub);
    const json = await result.json();
    if (!json || json.status !== "success") {
        throw new Error(
            "Could not register push subscription: " +
                (json.message || "Unknown error")
        );
    }

    triggerOnChange({ isSubscribed: true, subscription: sub });
    return true;
};

export const askForSubscription = async (
    reg?: ServiceWorkerRegistration
): Promise<PushSubscription> => {
    if (!reg) {
        reg = await navigator.serviceWorker.ready;
    }

    // Check if we already have a subscription
    let sub = await getPushSubscription();
    if (sub) {
        return sub;
    }

    // Create subscription
    const response = await SubscriptionAPI.getVapidKey();
    const json = await response.json();
    const vapidKey = urlBase64ToUint8Array(json.vapidKey);

    // Subscribe (this will ask the user for permission to send notifications)
    sub = await reg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: vapidKey,
    });

    if (sub === null) {
        // User rejected the permission request
        throw new Error("Could not subscribe to push notifications");
    }

    return sub;
};

type NotificationTelemetryData = {
    action: string;
    subscription: any;
    eventId: number;
};

const addListeners = () => {
    navigator.serviceWorker.addEventListener("message", async (evt) => {
        const payload = JSON.parse(evt.data);

        if (payload.type === "tel_notification_action") {
            storeNotificationTelemetry(
                payload.data as NotificationTelemetryData
            );
        }
    });
};

const storeNotificationTelemetry = async (data: NotificationTelemetryData) => {
    const result = await SubscriptionAPI.notificationTelemetry(
        data.subscription,
        data.eventId,
        data.action
    );
    const json = await result.json();
    if (!json || json.status !== "success") {
        throw new Error("Could not store notification telemetry");
    }
};

export const registerServiceWorker = async (
    shouldAskForSub: boolean = false
): Promise<boolean> => {
    if (!("serviceWorker" in navigator)) {
        throw new Error("Service workers are not supported by this browser");
    }

    const reg = await navigator.serviceWorker.register("./serviceworker.js");
    if (!reg) {
        throw new Error("Could not register service worker");
    }

    addListeners();

    let sub = await reg.pushManager.getSubscription();
    if (sub === null && shouldAskForSub) {
        // Create subscription
        sub = await askForSubscription(reg);
    }

    // Not an error, we just shouldn't ask for a subscription
    if (sub === null) {
        return false;
    }

    // It is fine to register the subscription even if it exists, it will just be a noop returning success.
    const accepted = await registerSubscription(sub);
    if (!accepted) {
        throw new Error("Could not register push subscription");
    }
    return true;
};
