import React, { useContext, useState, useEffect, useCallback } from "react";
import GameList from "../components/GameItems/GameList.js";
import { ShopContext } from "../context/ShopContextProvider";
import HeaderComponent from "../components/Search/HeaderComponent";
import Login from "../components/Login/Login.js";
import "../css/Background.css";
import { ButikAPI } from "../apis/ButikAPI.js";

function FavoriteGames() {
    const { userGames, isLoggedIn, appUser, userToken } = useContext(ShopContext);
    const [favorites, setFavorites] = useState([]);

    const fetchGames = useCallback((id) => {
        return ButikAPI.getProductsForUser(userToken, id, 0, 1)
            .then(r => r.json())
            .then(d => {
                if (d.status === "success") {
                    setFavorites(d.products);
                }
                else {
                    console.log(d)
                }
            })
    }, [userToken, setFavorites])

    useEffect(() => {
        if (isLoggedIn)
            //console.log(appUser);
            fetchGames(appUser.id_user);
    }, [isLoggedIn, appUser, fetchGames]);

    const updateGame = (item) => {
        if (!item.isFavorite) {
            setFavorites((prev) => {
                if (!prev?.includes(item)) {
                    return [item, ...prev];
                }
                else {
                    return prev;
                }
            })
        }
        else {
            setFavorites((prev) => {
                if (prev?.includes(item)) {
                    return prev.filter(i => i !== item);
                }
                else {
                    return prev;
                }
            })
        }

    }

    return (
        <div className={favorites.length > 2 ? "h-full" : "h-screen"}>
            <HeaderComponent header="Favoriter" />
            <div className="h-full fixed inset-x-0.5 top-14">
                {isLoggedIn ? <GameList games={favorites} updateGame={updateGame} /> :
                    <div className="grid grid-col-1 gap-5 p-2 h-full">
                        <Login doFetch={fetchGames} />
                    </div>}
            </div>
        </div>
    )
}

export default FavoriteGames;
