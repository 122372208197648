import React, { useState, useContext, useEffect } from "react";
import { Tabs, Tab, Input, Link, Button, Card, CardBody, ModalContent, ModalHeader, ModalBody, ModalFooter, Modal, useDisclosure, Switch } from "@nextui-org/react";

import { EyeFilledIcon } from "./EyeFilledIcon";
import { EyeSlashFilledIcon } from "./EyeSlashFilledIcon";
import { ShopContext } from "../../context/ShopContextProvider";
import "../../css/Background.css";
import { Checkbox } from "@mui/material";
import { ButikAPI } from "../../apis/ButikAPI";
import Terms from "../AccountItems/Terms";
import { registerAndAskForPushSubscription } from "../../util/registerServiceWorker.ts";

export default function Login(props) {
    const [selected, setSelected] = useState("login");
    const [isVisible, setIsVisible] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const { setIsLoggedIn, appUser, setAppUser, setUserToken, setShowLoading } = useContext(ShopContext);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [errMessage, setErrMessage] = useState("");
    const [saveCredentials, setSaveCredentials] = useState(false);
    const [readTermsOfUse, setReadTermsOfUse] = useState(false);
    const [isTermsOpen, setIsTermsOpen] = useState(false);
    const [showReset, setShowReset] = useState(false);

    const toggleVisibility = () => setIsVisible(!isVisible);

    useEffect(() => {
        if (localStorage.getItem("userEmail") !== null &&
            localStorage.getItem("userLogin") !== null &&
            appUser === null) {
            setEmail(localStorage.getItem("userEmail"));
            setPassword(localStorage.getItem("userLogin"));

            fetchUser();
        }

    }, [])

  const fetchUser = () => {
    setShowLoading(true);
    return ButikAPI.login(email, password)
        .then(res => res.json())
        .then(data => {
            setShowLoading(false);
            if (data && data.status === 'success') {
                initializeComponent(data);
                registerAndAskForPushSubscription().catch(console.log)
            } else {
                setErrMessage("Fel email eller lösenord. Prova igen.");
                onOpen();
            }
        })
    }

    const resetUserPassword = () => {
        setShowLoading(true);
        return ButikAPI.resetPassword(email)
        .then(res => res.json())
        .then(data => {
            setShowLoading(false);
            if (data && data.status === 'success') {
                setErrMessage("Ett email har skickats med ditt nya lösenord.");
                onOpen();
            } else {
                setErrMessage("Fel email. Prova igen.");
                onOpen();
            }
        })
    }
  /*
const saveNewUser = () => {
  return ButikAPI.register(email, password, name).then(res => res.json())
      .then(data => {
          if (data && data.status === 'success') {
              initializeComponent(data);
          }
          else {
              console.log(data);
              setErrMessage("Skapa nytt konto misslyckades. Kolla att alla fält är rätt och prova igen.");
              onOpen();
          }
      });
}

  const initializeComponent = (data) => {
    setAppUser(data.user);
    setUserToken(data.token);
    setIsLoggedIn(true);
    if(props.doAction !== null && props.doAction !== undefined) props.doAction();
    if(props.doFetch !== null && props.doFetch !== undefined) props.doFetch(data.id_user);
    if(saveCredentials) {
      localStorage.setItem("userEmail", data.email);
      localStorage.setItem("userLogin", data.password);
    }
} */

    const saveNewUser = () => {
        setShowLoading(true);
        return ButikAPI.register(email, password, name).then(res => res.json())
            .then(data => {
                setShowLoading(false);
                if (data && data.status === 'success') {
                    initializeComponent(data);
                    registerAndAskForPushSubscription().catch(console.log)
                }
                else {
                    setErrMessage(data.message);
                    onOpen();
                }
            });
    }

    const initializeComponent = (data) => {
        setAppUser(data.user);
        setUserToken(data.token);
        setIsLoggedIn(true);
        if (props.doAction !== null && props.doAction !== undefined) props.doAction();
        if (props.doFetch !== null && props.doFetch !== undefined) props.doFetch(data.id_user);
        if (saveCredentials) {
            localStorage.setItem("userEmail", email);
            localStorage.setItem("userLogin", password);
        }
        else {
            localStorage.removeItem("userEmail", email);
            localStorage.removeItem("userLogin", password);
        }
    }

    const handleLogin = () => {
        setIsTermsOpen(false);
        if (email === "" || password === "") {
            setErrMessage("Både email och lösenord behövs för att logga in.");
            onOpen();
        }
        else {
            fetchUser();
        }

    }

    const handleNewAccount = () => {
        setIsTermsOpen(false);
        if (email === "" || password === "" || name === "") {
            setErrMessage("Alla fält behövs för att skapa nytt konto.");
            onOpen();
        }
        else if (!(email.includes("@") && email.includes("."))) {
            setErrMessage("Email har inte rätt format.");
            onOpen();
        }
        else if (!readTermsOfUse) {
            setErrMessage("Du måste läsa och godkäna användarvillkor för att skapa nytt konto.");
            onOpen();
        }
        else {
            console.log("save user");
            saveNewUser();
        }
    }

    const openTerms = () => {
        setErrMessage("Long text with tearms of use.");
        setIsTermsOpen(true);
        onOpen();
    }

    const resetPassword = () => {
        if (email === "") {
            setErrMessage("Email behövs för att återställa lösenord.");
            onOpen();
        }
        else {
            resetUserPassword();
        }
    }

    return (
        <div className="flex flex-col w-full h-full pt-2" style={{ color: "#504459" }}>
            <Card className="h-3/4 bg-white bg-opacity-40 backgroundPic">
                <CardBody className="h-full absolute z-10 top-1">
                    <Tabs
                        fullWidth
                        size="md"
                        aria-label="Tabs form"
                        selectedKey={selected}
                        onSelectionChange={setSelected}
                    >
                        <Tab key="login" title="Logga in">
                            {showReset ?
                                <form className="flex flex-col gap-4">
                                    <Input isRequired
                                        label="Email"
                                        type="email"
                                        value={email}
                                        onValueChange={setEmail} />
                                    <p className="text-center text-small">
                                        Testa logga in igen?{" "}
                                        <Link size="sm" onPress={() => setShowReset(false)}>
                                            Gå tillbaka
                                        </Link>
                                    </p>
                                    <div className="flex gap-2 justify-end">
                                        <Button style={{ backgroundColor: "#504459" }}
                                            className="text-lg text-white"
                                            variant="flat"
                                            fullWidth
                                            onClick={resetPassword}>
                                            Återställ lösenord
                                        </Button>
                                    </div>
                                </form>:
                                <form className="flex flex-col gap-4">
                                    <Input isRequired
                                        label="Email"
                                        type="email"
                                        value={email}
                                        onValueChange={setEmail} />
                                    <Input isRequired
                                        label="Lösenord"
                                        endContent={
                                            <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                                {isVisible ? (
                                                    <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                                ) : (
                                                    <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                                                )}
                                            </button>
                                        }
                                        type={isVisible ? "text" : "password"}
                                        value={password}
                                        onValueChange={setPassword} />
                                    <div
                                        onClick={() => setSaveCredentials(!saveCredentials)}
                                        className="flex justify-center items-center text-small">
                                        <Checkbox
                                            size="small"
                                            checked={saveCredentials} >
                                        </Checkbox>
                                        <span>Spara inloggningsuppgifter</span>
                                    </div>
                                    <p className="text-center text-small">
                                        Behöver du skapa ett konto?{" "}
                                        <Link size="sm" onPress={() => setSelected("sign-up")}>
                                            Skapa konto
                                        </Link>
                                    </p>
                                    <p className="text-center text-small">
                                        Har du glömt lösenord?{" "}
                                        <Link size="sm" onPress={setShowReset}>
                                            Återställ
                                        </Link>
                                    </p>
                                    <div className="flex gap-2 justify-end">
                                        <Button style={{ backgroundColor: "#504459" }}
                                            className="text-lg text-white"
                                            variant="flat"
                                            fullWidth
                                            onClick={handleLogin}>
                                            Logga in
                                        </Button>
                                    </div>
                                </form>
                            }
                        </Tab>

            <Tab key="sign-up" title="Skapa konto">
              <form className="flex flex-col gap-4 h-[300px]">
                <Input
                  isRequired
                  label="Namn"
                  type="name"
                  value={name}
                  onValueChange={setName}/>
                <Input
                  isRequired
                  label="E-postadress"
                  type="email"
                  value={email}
                  onValueChange={setEmail}/>
                <Input
                  isRequired
                  label="Lösenord"
                  type="password"
                  value={password}
                  onValueChange={setPassword}
                />
                <div
                  onClick={() => setReadTermsOfUse(!readTermsOfUse)}
                  className="flex justify-center items-center text-small">
                    <Checkbox
                      size="small"
                      checked={readTermsOfUse} >
                    </Checkbox>
                    <span>
                      Jag har läst och godkänt {" "}
                      <Link size="sm" onPress={openTerms}>
                        användarvillkor.
                      </Link>
                      </span>
                </div>
                <p className="text-center text-small">
                  Har du redan ett konto?{" "}
                  <Link size="sm" onPress={() => setSelected("login")}>
                    Logga in
                  </Link>
                </p>
                <div className="flex gap-2 justify-end">
                  <Button style={{backgroundColor: "#504459"}}
                            className="text-lg text-white"
                            variant="flat"
                            fullWidth
                            onClick={handleNewAccount}>
                    Skapa konto
                  </Button>
                </div>
              </form>
            </Tab>
          </Tabs>
        </CardBody>
      </Card>

      <Modal
        placement={isTermsOpen ? "center" : "bottom"}
        size={isTermsOpen ? "full" : "sm"}
        scrollBehavior="inside"
        isOpen={isOpen}
        onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {isTermsOpen ? "Användarvillkor" : "Misslyckas" }
              </ModalHeader>
              <ModalBody>
                {isTermsOpen ? <Terms /> : errMessage}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Stäng
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}
