import React, { useState, createContext, useEffect } from "react";
import { associateUser, onSubscriptionChange } from "../util/registerServiceWorker";

export const ShopContext = createContext(null);

export const ShopContextProvider = (props) => {
    const [cartItems, setCartItems] = useState([]);
    const [appUser, setAppUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userToken, setUserToken] = useState(null);
    const [userGames, setUserGames] = useState([]);
    const [player, setPlayer] = useState(null);
    const [isHost, setIsHost] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [displayMenu, setDisplayMenu] = useState("block");
    const [klarnaOrderId, setKlarnaOrderId] = useState('');
    const URL_MEDIA = "https://www.pimparty.se/media/";
    const URL_ADDS = "https://www.pimparty.se/admin/uploads/marketing/";

    onSubscriptionChange(evt => {
        if (evt.isSubscribed && appUser !== null && appUser.id_user !== null) {
            associateUser(appUser.id_user).catch(console.trace);
        }
    });

    // Keep the user logged in even with refresh
    useEffect(() => {
        const appUserStorage = localStorage.getItem("appUser");
        const isLoggedInStorage = localStorage.getItem("isLoggedIn");
        const userTokenStorage = localStorage.getItem("userToken");

        if (appUserStorage) {
            setAppUser(JSON.parse(appUserStorage));
        }
        if (isLoggedInStorage) {
            setIsLoggedIn(JSON.parse(isLoggedInStorage));
        }
        if (userTokenStorage) {
            // TODO: We should make an API request to check if the token is still valid,
            // and then take action for removing the state data if it has expired.
            setUserToken(JSON.parse(userTokenStorage));
        }
    }, [setAppUser, setIsLoggedIn, setUserToken]);

    useEffect(() => {
        localStorage.setItem("appUser", JSON.stringify(appUser));
    }, [appUser]);
    useEffect(() => {
        localStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
    }, [isLoggedIn]);
    useEffect(() => {
        localStorage.setItem("userToken", JSON.stringify(userToken));

        // Check token value
        if (userToken !== null && appUser !== null) {
            // assume token is valid and associate the user with a push subscription (if possible)
            associateUser(appUser.id_user).catch(console.trace);
        }
    }, [userToken, appUser]);

    const isListItemAdded = (list, item) => {
        return list?.filter(i => i.id === item.id).length > 0;
    }

    const addToCart = (item) => {
        setCartItems((prev) => {
            if (isListItemAdded(prev, item)) {
                return prev
            }
            else {
                return [item, ...prev];
            }
        });
        setKlarnaOrderId('');
    }

    const removeFromCart = (item) => {
        setCartItems((prev) => {
            if (isListItemAdded(prev, item)) {
                return prev.filter(i => i !== item);
            }
            else {
                return prev;
            }
        });
        setKlarnaOrderId('');
    }

    const addToUserGames = (item) => {
        setUserGames((prev) => {
            if (!isListItemAdded(prev, item)) {
                return [item, ...prev];
            }
            else {
                return prev;
            }
        })
    }

    const removeFromUserGames = (item) => {
        setUserGames((prev) => {
            if (isListItemAdded(prev, item)) {
                return prev.filter(i => i !== item);
            }
            else {
                return prev;
            }
        })
    }

    const clearCart = () => { setCartItems([]) }

    const contextValue = {
        cartItems,
        addToCart,
        removeFromCart,
        appUser,
        setAppUser,
        isLoggedIn,
        setIsLoggedIn,
        userGames,
        setUserGames,
        addToUserGames,
        removeFromUserGames,
        player,
        setPlayer,
        isHost,
        setIsHost,
        URL_MEDIA,
        URL_ADDS,
        userToken,
        setUserToken,
        clearCart,
        showLoading,
        setShowLoading,
        displayMenu,
        setDisplayMenu,
        klarnaOrderId,
        setKlarnaOrderId
    }

    //console.log(cartItems);

    return <ShopContext.Provider value={contextValue}>{props.children}</ShopContext.Provider>
}
