import React, { useContext } from "react";
import HeaderComponent from "../components/Search/HeaderComponent";
import { ShopContext } from "../context/ShopContextProvider";
import CartList from "../components/CartItems/CartList";
import { Button } from "@nextui-org/react";

function ShoppingCart(props){

    const { cartItems, setSelected } = useContext(ShopContext);

    const handlePress = () => {
        //console.log("go to butik");
        if(props.setStartPage !== null && props.setStartPage !== undefined){
            props.setStartPage({name: "Home", route: "/pim", position: 0});
        }
    }

    return(
       /*  <div>
            <HeaderComponent header="Varukorg" />
            <CartListContainer />
        </div>     */

        <div className={cartItems.length < 4 ? "h-screen" : ""}>
            <HeaderComponent header="Varukorg" />
            <div className="h-full fixed inset-x-0.5 top-14">
                {cartItems.length > 0 ?
                    <CartList cartItems={cartItems} goToShop={handlePress}/>:
                    <div className="flex justify-center">
                        <span style={{color: "#504459"}} className="pt-10 text-xl font-semibold">
                            Varukorgen är tom.
                        </span>
                        <div className="fixed bottom-14 w-full left-0 p-5 pb-8">
                            <div className="grid grid-cols-1">
                                <Button style={{backgroundColor: "#504459"}}
                                    className="h-14 text-xl text-white"
                                    variant="flat"
                                    onPress={handlePress}>Gå till butiken</Button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ShoppingCart;
