import React, { useContext, useEffect } from "react";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Button, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import parse from 'html-react-parser';
import { ShopContext } from "../../context/ShopContextProvider";

const tabbar = [
    {name: "Home", route: "/pim", position: 0},
    {name: "Favorites", route: "/favoritegames", position: 1},
    {name: "Play", route: "/playgame", position: 2},
    {name: "ShoppingCart", route: "/shoppingcart", position: 3},
    {name: "MyAccount", route: "/myaccount", position: 4},
]

function HeaderComponent(props){
    const navigate = useNavigate();
    const {setDisplayMenu} = useContext(ShopContext);

    const handleGoBack = () => {
        const tabElement = tabbar.find(exp => exp.route === props.goBackScreen);
        if(tabElement !== null && tabElement !== undefined &&
                props.goBack !== null && props.goBack !== undefined)
            console.log(tabElement);
        else
        {
            setDisplayMenu("block");
            navigate(props.goBackScreen);
        }
    }

    useEffect(() => {
        console.log(props.header);
        console.log(props.info);
    }, []);

    return(
        <div className="inset-x-2.5 top-2.5 fixed">
        {props.goBackScreen !== undefined ?
        <div style={{color: "#504459"}} className="grid grid-cols-12 rounded-2xl flex justify-center items-center text-black shadow-lg p-2 bg-white bg-opacity-40 text-xl font-semibold pr-5">
            <Button className="col-span-2"
                variant="light"
                isIconOnly
                aria-label="GoBack"
                size="sm"
                onPress={handleGoBack}>
                <ArrowBackOutlinedIcon />
            </Button>
            <span className="col-span-9">{props.header}</span>
            {
                props.info !== undefined ?
                <Popover placement="left-end">
                    <PopoverTrigger>
                        <Button className="col-span-1"
                            variant="light"
                            isIconOnly
                            aria-label="Info">
                                <InfoOutlinedIcon />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                        <span>{parse(props.info)}</span>
                    </PopoverContent>
                </Popover>
                : ""
            }
        </div>
        :
        <div style={{color: "#504459"}} className="rounded-2xl flex justify-center text-black shadow-lg p-2 bg-white bg-opacity-40 text-xl font-semibold">
            {props.header}
        </div>}
    </div>
    )
}

export default HeaderComponent;
