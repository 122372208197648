import React, {useState, useEffect, useContext, useCallback, useRef} from "react";
import GameList from "../components/GameItems/GameList.js";
import SearchComponent from "../components/Search/SearchComponent";
import { Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Input, Chip } from "@nextui-org/react";
import { ShopContext } from "../context/ShopContextProvider";
import { ButikAPI } from "../apis/ButikAPI";
import Filter from "../components/Search/Filter.js";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';

const screenStyle = {
    innerHeight: window.outerHeight
}
function AllGames(props) {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [data, setData] = useState();
    const [products, setProducts] = useState();
    const [filterOn, setFilterOn] = useState(false);
    const isFirstRender = useRef(true);
    const [allCategories, setAllCategories] = useState();
    const [categories, setCategories] = useState();
    const [subCategories, setSubCategories] = useState();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [sort, setSort] = useState("newest");
    const [filterStyle, setFilterStyle] = useState({zIndex: 80});
    const { isLoggedIn, appUser, URL_API,
        setShowLoading, setAppUser, setIsLoggedIn, userToken, setUserToken } = useContext(ShopContext);

    const fetchData = useCallback(() => {
      let promise;
      console.log(isLoggedIn)
      if (isLoggedIn) {
          promise = ButikAPI.getProductsForUser(userToken, appUser.id_user);
      }
      else {
          promise = ButikAPI.getProducts();
      }
      return promise.then(r => r.json()).then(d => {
          if (d.status === "success") {
                setData(d.products);
                setProducts(d.products);
          }
          else {
              console.log(d);
          }
          setShowLoading(false);
          setFilterOn(false);
      })
  }, [])

    const fetchCategories = useCallback(() => {
        return ButikAPI.getCategories()
            .then(r => r.json())
            .then(d => {
                if (d.status === "success") {
                    setAllCategories(d.categories);
                    setCategories(d.categories.filter(exp => exp.sub_category !== 1));
                    setSubCategories(d.categories.filter(exp => exp.subcategory !== 0));
                }
                else {
                    console.log(d)
                }
        })
    }, [])

    const fetchUser = () =>{
        return ButikAPI.login(localStorage.getItem("userEmail"), localStorage.getItem("userLogin"))
        .then(res => res.json())
        .then(d => {
            if (d.status === 'success') {
                console.log(d);
                setAppUser(d.user);
                setUserToken(d.token);
                setIsLoggedIn(true);
                fetchData();
            }
        })
     /*  return fetch(URL_API + "command=get_users&email=" + localStorage.getItem("userEmail")
      + "&password=" + localStorage.getItem("userLogin"))
        .then((res) => res.json())
        .then((d) => {
          console.log(d);
          setAppUser(d);
          setIsLoggedIn(true);
          fetchData();
        }); */
    }

    useEffect(() => {
        if(allCategories === null || allCategories === undefined)
            fetchCategories();
        // set appuser
        if(localStorage.getItem("userEmail") !== null &&
        localStorage.getItem("userLogin") !== null &&
        appUser === null) {
            fetchUser();
            }
        else if (data === null || data === undefined){
          fetchData();
        }

        if(props.addsFilter !== undefined && data?.length > 0){
            setData(data.filter(exp => props.addsFilter.includes(exp.id)));
            setFilterOn(true);
        }
    }, [props.addsFilter]);

    const search = (wordToSearch) => {
        console.log(wordToSearch);
        console.log(data);
        if (wordToSearch !== "") {
            setData((prev) => {
                return prev.filter(i => i.name.toLowerCase().includes(wordToSearch.toLowerCase())
                    || i.shopDescription.toLowerCase().includes(wordToSearch.toLowerCase())
                    || i.description.toLowerCase().includes(wordToSearch.toLowerCase()));
            });
        }
        else {
            setData(products);
        }
    }

    const filter = (selectedC, selectedT, s) => {
        if(selectedC?.length > 0 || selectedT?.length >0 || s !== undefined){
            setData((prev) => {
                prev = prev.filter(exp =>
                                (selectedC.length === 0 || exp.category.includes(selectedC))//selectedCategories.some(e => e === parseInt(exp.category)))
                                && (selectedT.length === 0 || exp.subCategory.includes(selectedT)));//selectedTags.some(e => e === parseInt(exp.subCategory))));
                switch(s){
                    case "priceAsc":
                        prev = prev.sort((a, b) => a.price - b.price);
                        break;
                    case "priceDesc":
                        prev = prev.sort((a, b) => b.price - a.price);
                        break;
                    default:
                        prev = prev.sort((a, b) => b.id - a.id);
                        break;
                }

                return prev;

            })

            setSelectedCategories(selectedC);
            setSelectedTags(selectedT);
            setSort(s);
            setFilterOn(true);
        }
        else if(filterOn)
            setData(products);
        else
            setFilterOn(false);
        onOpenChange();
        setFilterStyle({zIndex: 80});
    }

    const updateGame = (item) => {
        const newData = data.map((game) => {
            if (game.id === item.id) {
                return { ...game, isFavorite: !item.isFavorite };
            }

            return game;
        });

        setData(newData);
    }

    const closeFilter = () => {
        setFilterOn(false);
        setShowLoading(true);
        setData(products);
        setShowLoading(false);
    }

    const cancelFilter = () =>{
        setSelectedCategories([]);
        setSelectedTags([]);
        setSort('newest');
        closeFilter();
        onOpenChange();
        setFilterStyle({zIndex: 80});
    }

    const openFilter = () => {
        setFilterStyle({zIndex: 80});
        onOpen();
    }

    return (
        <div className={data?.length > 2 ? "h-full" : "h-screen"}>
            <div className="p-2">
                <div className="gap-5 rounded-2xl flex justify-between text-white shadow-lg">
                    <SearchComponent className="z-10" search={search} />
                    <Button isIconOnly variant="faded" onPress={openFilter} style={filterStyle}>
                        <TuneOutlinedIcon />
                    </Button>
                </div>
            </div>


            <div className="pt-2">
                {filterOn ?
                    <Chip onClose={() => closeFilter()} variant="bordered">Återställ filter</Chip>
                : "" }
            </div>

            <div className="fixed h-screen">
                <GameList games={data} updateGame={updateGame}/>
            </div>

            <Modal isOpen={isOpen} onOpenChange={onOpenChange}
                size="full"
                placement="center" >
              <ModalContent>
                  <>
                    <ModalHeader>
                      Filter
                    </ModalHeader>
                    <ModalBody>
                      <Filter
                        filter={filter}
                        categories={categories}
                        subCategories={subCategories}
                        selectedCategories={selectedCategories}
                        selectedTags={selectedTags}
                        sort={sort}
                        cancelFilter={cancelFilter}
                        />
                    </ModalBody>
                  </>
              </ModalContent>
            </Modal>

        </div>
    );
}

export default AllGames;
