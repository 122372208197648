import React, { useContext } from "react";
import host from "../images/hostimg.png";
import guest from "../images/guestimg.png";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Image, useDisclosure } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../components/Search/HeaderComponent";

import "../css/Background.css";
import Login from "../components/Login/Login";
import { ShopContext } from "../context/ShopContextProvider";


function PlayGame(){
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const {isLoggedIn, setDisplayMenu} = useContext(ShopContext);
    const navigate = useNavigate();

    const hostClick = () => {
        if(!isLoggedIn)
            onOpen();
        else
        {
            setDisplayMenu("none");
            navigate("/hostscreen", {state: {data: -1}});
        }
        console.log("host");
    }

    const guestClick = () => {
        setDisplayMenu("none");
        navigate("/guestscreen");
        console.log("guest");
    }

    return (
        <div className="h-screen backgroundPic">
            <HeaderComponent header="Starta lek" />

            <div className="h-full fixed inset-x-0.5 top-14">
                <div className={window.innerHeight < 800 ? "p-10 pt-5 flex justify-center" : "p-2 pt-10 flex justify-center"} >
                    <div className={window.innerHeight > window.innerWidth ? "w-4/5" : "flex justify-between"}>
                        <div>
                            <Image
                                src={host} alt="hostimg"
                                onClick={() => hostClick()} />
                        </div>
                            <div>
                            <Image
                                src={guest} alt="guestimg"
                                onClick={() => guestClick()} />
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="center" size="full">
                <ModalContent>
                {(onClose) => (
                    <>
                    <ModalHeader className="flex flex-col gap-1 pb-0">Du måste logga in för att starta en lek</ModalHeader>
                    <ModalBody className="p-1">
                        <Login doAction={onClose}/>
                    </ModalBody>
                    </>
                )}
                </ModalContent>
            </Modal>
        </div>
    )
}

export default PlayGame;
