import React, {useContext} from "react";
import { ShopContext } from "../../context/ShopContextProvider";
import CartItem from "./CartItem";
import PurchaseGame from "./PurchaseGames";
import { Modal, ModalBody, ModalContent, ModalHeader, useDisclosure } from "@nextui-org/react";
import Login from "../Login/Login";

function CartList(props) {

    const { cartItems } = useContext(ShopContext);
    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    return(
        <>
        <div className="pb-64 h-full overflow-y-auto">
            <div className="px-2 flex flex-col gap-2 col-span-6 pt-5 ">

                {cartItems?.map((game) => <CartItem game={game} key={game.id}/>)}

            </div>
        </div>

        <PurchaseGame goToShop={props.goToShop} openLogin={onOpen}/>

        <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="center" size="full">
            <ModalContent>
            {(onClose) => (
                <>
                <ModalHeader className="flex flex-col gap-1 pb-0">Du måste logga in för att köpa lekar</ModalHeader>
                <ModalBody className="p-1">
                    <Login doAction={onClose}/>
                </ModalBody>
                </>
            )}
            </ModalContent>
        </Modal>
        </>
    );
};

export default CartList;
