export const KlarnaOrder = (amount, items) => {
    var result = {
        "purchase_country": "SE",
        "purchase_currency": "SEK",
        "locale": "sv-SE",
        "order_amount": amount * 100,
        // Räkning enligt Klarna
        "order_tax_amount": (amount * 100) - (amount * 100) * 10000/(12500),
        "order_lines": items,
        "merchant_urls": {
            "terms": "https://www.pimparty.se/admin/Klarna/KlarnaTerms.html",
            "checkout": "https://www.pimparty.se/admin/Klarna/KlarnaPageCheckout.php?klarna_order_id={checkout.order.id}",
            "confirmation": "https://www.pimparty.se/admin/Klarna/KlarnaPageConfirmation.php?klarna_order_id={checkout.order.id}",
            "push": "https://www.pimparty.se/admin/Klarna/KlarnaPagePush.php?klarna_order_id={checkout.order.id}",
        }
    }

    return result;
}

export const KlarnaItem = (id, name, price) => {
    var result =  {
        "type": "digital",
        "reference": id,
        "name": name,
        "quantity": 1,
        "quantity_unit": "styck",
        "unit_price": price * 100,
        "tax_rate": 2500,
        "total_amount": price * 100,
        "total_discount_amount": 0,
        // Räkning enligt Klarna total_amount - total_amount * 10000 / (10000 - tax_rate)
        "total_tax_amount": (price * 100) - (price * 100) * 10000/(12500)
    };

    return result;
}
