import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { ShopContext } from "../../context/ShopContextProvider";
import { Image } from "@nextui-org/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
function Slider (props){
    const { URL_MEDIA } = React.useContext(ShopContext);
    useEffect(() => {
        var test = props.images;
    }, []);

    return(
        <div className="p-1">
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                >
                    {props.images?.filter(exp => exp.url !== '').map((image) =>(
                        <SwiperSlide key={image.imgId}>
                            <Image
                                shadow="sm"
                                radius="lg"
                                alt={image.imgId}
                                className="w-[400px] object-cover h-[240px]"
                                src={image.url}
                            />
                        </SwiperSlide>
                    ))}

            </Swiper>
        </div>
    )
}

export default Slider;

