import React, { useCallback, useState } from "react";
import { GameDataList } from "../../api/GameData";
import HeaderComponent from "../Search/HeaderComponent";
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, Textarea, useDisclosure } from "@nextui-org/react";
import { ButikAPI } from "../../apis/ButikAPI";

function Contact() {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [info, setInfo] = useState();

    const saveMessage = useCallback((name, email, message) => {
        return ButikAPI.postNewMessage(name, email, message).then(r => r.json())
            .then(d => {
                if (d.status === "success") {
                    setName('');
                    setEmail('');
                    setMessage('');

                    setInfo('Vi har fått ditt meddelande och återkommer så snabbt vi kan!');
                    onOpen();
                }
                else {
                    console.log(d);
                    setInfo('Tyvärr gick inte att skicka ditt meddelande. Kontrollera angiven e-postaddres eller försök igen senare.');
                    onOpen();
                }
            });
    }, [])

    const sendMessage = () => {
        console.log('Send message.');
        console.log(name);
        console.log(email);
        console.log(message);
        saveMessage(name, email, message);
    }
    return(
        <div className="container h-screen">
            <HeaderComponent header="Kontakta oss" goBackScreen="/myaccount"/>
            <div className="fixed inset-x-2.5 top-20">
                <div style={{color: "504459"}} className=" grid grid-cols-1 gap-5 h-fit rounded-2xl shadow-lg p-2 bg-white bg-opacity-40 text-xl font-semibold">
                    <span className="pt-10 text-xl font-semibold">
                            Hur kan vi hjälpa dig?
                    </span>
                    <span className="text-lg font-normal">
                        Kontakta oss mer än gärna. Vi svarar alltid inom 24 timmar (vardagar).
                    </span>
                    <Input
                        isRequired
                        label="Namn"
                        type="name"
                        placeholder="Skriv in ditt namn"
                        value={name}
                        onValueChange={setName}
                        className="shadow rounded-2xl"/>
                    <Input
                        isRequired
                        label="E-postadress"
                        type="email"
                        placeholder="Skriv in din e-postadress"
                        value={email}
                        onValueChange={setEmail}
                        className="shadow rounded-2xl"/>
                    <Textarea
                        isRequired
                        label="Meddelande"
                        placeholder="Lämna ett meddelande"
                        value={message}
                        onValueChange={setMessage}
                        className="shadow rounded-2xl"/>
                    <div className="pb-10 grid grid-cols-1">
                        <Button
                            style={{backgroundColor: "#504459"}}
                            className="h-14 text-xl text-white shadow"
                            variant="flat"
                            onPress={sendMessage}>Skicka</Button>
                    </div>
                </div>
            </div>

            <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                {(onClose) => (
                    <>
                    <ModalBody>
                        <p>
                        {info}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" variant="light" onPress={onClose}>
                        Close
                        </Button>
                    </ModalFooter>
                    </>
                )}
                </ModalContent>
            </Modal>
        </div>
    );
}

export default Contact;
