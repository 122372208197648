import React from 'react';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const iconClasses = "text-xl text-default-500 pointer-events-none flex-shrink-0";

const MenuIcon = ({ name }) => {
    return (
        <>
            {
                {
                    "mygames"  : <WidgetsOutlinedIcon className={iconClasses}/>,
                    "settings"  : <SettingsOutlinedIcon  className={iconClasses}/>,
                    "contact": <ContactSupportOutlinedIcon className={iconClasses}/>,
                    "termsofuse": <LibraryBooksOutlinedIcon className={iconClasses}/>,
                    "logout": <LogoutOutlinedIcon  className={iconClasses}/>,
                    "about": <InfoOutlinedIcon  className={iconClasses}/>,
                    "end": <ArrowForwardIosOutlinedIcon className={iconClasses}/>
                }[name]
            }
        </>
    )
}

export default MenuIcon;
