import React, { useContext, useState } from "react";
import { GameDataList } from "../../api/GameData";
import { Button, Modal, ModalContent, Switch, ModalHeader, ModalFooter, ModalBody, useDisclosure, Input } from "@nextui-org/react";
import HeaderComponent from "../Search/HeaderComponent";
import { useNavigate } from "react-router-dom";
import { ShopContext } from "../../context/ShopContextProvider";
import { ButikAPI } from "../../apis/ButikAPI";

function Settings() {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { setIsLoggedIn, appUser, setAppUser, userToken, setShowLoading } = useContext(ShopContext);
    const navigate = useNavigate();
    const [newPass, setNewPass] = useState('');
    const [newPassCopia, setNewPassCopia] = useState('');

    const msgDelete = "Är du säkert att du vill radera ditt konto? Allt data och alla lekar kommer att raderas och du kommer inte ha tillgång till dem längre.";

    const confirmDelete = (e) => {
        // delete user and all purchased games
        deleteUser().then(s => {
            if (!s) {
                alert('Kunde inte radera användaren.');
                return;
            }

            // set user to null
            setAppUser(null);
            setIsLoggedIn(false);

            // close the modal
            onOpenChange();

            // got to account page
            navigate("/myaccount");
        });
    }

    const deleteUser = async () => {
        const r = await ButikAPI.deleteUser(appUser.id_user, userToken);
        const d = await r.json();
        if (!d || d.status !== 'success') {
            console.error('Failed to delete user: ', d);
            return false;
        }

        console.log('User deleted');
        return true;
    }

    const changePass = () =>{
        if(newPass === newPassCopia){
            setShowLoading(true);
            ButikAPI.changePassword(appUser?.email, newPass, userToken)
            .then(res => res.json())
            .then(data => {
                setShowLoading(false);
                if (data && data.status === 'success') {
                    alert('Lösenord har ändrats.')
                    setNewPass('');
                    setNewPassCopia('');
                } else {
                    alert("Ett fel har inträffas. Prova igen.");
                }
            });
        }
        else
            alert('Båda lösenord måste vara lika.');
    }

   // const myGames = GameDataList.filter(game => game.isBought === true);
    return (
        <div className="container h-screen">
            <HeaderComponent header="Inställningar" goBackScreen="/myaccount" />
            <div className="fixed inset-x-2.5 top-20">
                <div style={{ color: "504459" }} className="rounded-2xl shadow-lg p-2 bg-white bg-opacity-40 text-xl font-semibold grid grid-cols-1">
                    <div className="h-14 flex justify-between items-center pl-2">
                        <span>Få aviseringar</span>
                        <Switch defaultSelected aria-label="notifications" />
                    </div>

                    <div className="grid grid-cols-1 gap-3 py-10">
                    <span className="justify-self-start">Återställ lösenord</span>
                    <Input isRequired
                        id="discountInput"
                        label="Nytt lösenord"
                        type="text"
                        value={newPass}
                        onValueChange={setNewPass} />
                    <Input isRequired
                        id="discountInput"
                        label="Repetera nytt lösenord"
                        type="text"
                        value={newPassCopia}
                        onValueChange={setNewPassCopia} />
                    <div className="pt-2 flex justify-between">
                        <span > </span>
                        <Button style={{backgroundColor: "#504459"}}
                            className="h-8 text-white"
                            variant="flat"
                            color="danger"
                            aria-label="Buy"
                            onPress={changePass}>
                                    Ändra
                        </Button>
                    </div>
                    </div>

                    <Button style={{ backgroundColor: "#504459" }}
                        className="text-lg text-white"
                        variant="flat"
                        onPress={onOpen}>Radera konto</Button>
                </div>
            </div>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Radera konto</ModalHeader>
                            <ModalBody>
                                {msgDelete}
                            </ModalBody>
                            <ModalFooter>
                                <Button color="default" variant="light" onPress={onClose}>
                                    Stäng
                                </Button>
                                <Button color="danger" variant="light" onPress={confirmDelete}>
                                    Radera
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
}

export default Settings;
