import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Image, Input } from "@nextui-org/react";
import { ShopContext } from "../../../context/ShopContextProvider";

function GuessPic(props){
    const [block, setBlock] = useState(props.games[0]);
    const { URL_MEDIA } = useContext(ShopContext);

    useEffect(() => {
        console.log(props.idGame);
        setBlock(props.games[0]);
    }, []);

    return(
            <Card className=" bg-white bg-opacity-90" shadow="lg">
                <CardBody>
                {
                    {
                        [block?.tips_1]  :
                                <div style={{color: "504459"}} className="flex text-center items-center justify-center flex-col p- text-xl font-semibold h-full">
                                    <span>{block?.tips_1}</span>
                                </div>,
                        [block?.tips_x]  :
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src={block?.tips_x}
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Embedded youtube"
                                />,
                        [block?.tips_2]   :
                                <Image
                                    shadow="sm"
                                    radius="lg"
                                    width="100%"
                                    alt="block1_pic"
                                    className="w-full object-cover h-full"
                                    src={URL_MEDIA + props.idGame + "/" + block.tips_2} />
                    }[block?.answer]
                }
                </CardBody>
            </Card>
    )
}

export default GuessPic;
