import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, Input } from "@nextui-org/react";

function GuessText(props){
    const [locked, setLocked] = useState(false);
    const [score, setScore] = useState(0);
    const [answers, setAnswers] = useState(props.answers);

    useEffect(() =>{
        console.log(props.games);
        console.log(props.currentAnswer);
        setLocked(false);
        setScore(0);
    }, [props.answers, props.games])

    const handleChange = (e, id) => {
        props.changeAnswers({id_bb_data: id, answer: e.target.value});
      }

      const changeScore = e => {
        console.log(e.target.className);
        // if the button is green then the answer turns back
        if(e.target.className.includes("bg-green-400")){
            setScore(score-1);
            e.target.className = e.target.className.replaceAll("bg-green-400", "")
        }
        // else the button turns green and score ++
        else {
            setScore(score+1);
            e.target.className += " bg-green-400";
        }
      }

      const saveAnswers = () =>{
        props.changeAnswers(null, score);
        setScore(0);
      }

      const changeAnswer = (e, id) => {
        setAnswers((prev) => {
             const index = prev.findIndex((item) => item.id_bb_data === id);
             if(index !== -1) {
                const newAnswer = prev.map((item) =>
                item.id_bb_data === id ? {...item, answer: e.target.value} : item);

                return newAnswer;
             }

             return [...prev, {id, answer: e.target.value}];
        })
    }

    return(
            <Card className=" bg-white bg-opacity-90" shadow="lg">
                <CardBody>
                        {props.games.map((input =>
                            <div key={input.id_bb_data} className="grid grid-cols-2 gap-2 items-center pb-10">
                                <span>{input.question}</span>
                                {
                                    locked ?
                                    <Button
                                        variant="faded"
                                        onPress={changeScore}>
                                        {props.answers.find(exp => exp.id_bb_data === input.id_bb_data)?.answer}
                                    </Button>:
                                    <Input label="Svar" variant="underlined"
                                        className="border-b-2 border-gray-500"
                                        value={answers?.find(exp => exp.id_bb_data === input.id_bb_data)?.answer}
                                        onBlur={(e) => handleChange(e, input.id_bb_data)}
                                        onChange={(e) => changeAnswer(e, input.id_bb_data)}/>
                                }
                            </div>
                        ))}
                </CardBody>
                <CardFooter>
                        {
                            locked ?
                            "":
                            <Button
                                className="shadow bg-gray-200 text-lg p-5"
                                onPress={setLocked}>  Rätta dina svar</Button>
                        }

                </CardFooter>
            </Card>
    )
}

export default GuessText;
