import { butikApiConfig } from "./configs/fetchConfig.ts";

export const ButikAPI = {
    login: (email, password) => {
        return butikApiConfig.post("/login", { email, password });
    },
    register: (email, password, username) => {
        return butikApiConfig.post("/register", { email, password, username });
    },
    updateUserProduct: (token, gameId, bought, favorite) => {
        return butikApiConfig.withAuth(token).post(`/user/products/update`, {
            gameId: +gameId,
            bought: +bought,
            favorite: +favorite,
        });
    },
    updateSeveralProducts: (token, items) => {
        return butikApiConfig.withAuth(token).post(`/user/products/updateseveral`, {
            items: items
        });
    },
    getProductsForUser: (token, userId, bought, favorite) => {
        return butikApiConfig.withAuth(token).get(`/user/${userId}/products`, {
            favorite: favorite ? +favorite : null,
            bought: bought ? +bought : null,
        });
    },
    getProducts: () => {
        return butikApiConfig.get('/products');
    },
    getHistoryForUser: (token, userId, productId) => {
        return butikApiConfig.withAuth(token).get(`/user/${userId}/history`, {
            productId: productId ? +productId : 0
        });
    },
    saveGameplay: (token, code, gameId, userId, statusId, share, teams) => {
        return butikApiConfig.withAuth(token).post("/gameplay/save", {
            code: code,
            gameId: gameId,
            userId: userId,
            statusId: statusId,
            share: share,
            teams: teams
        })
    },
    getGameplayById: (code) => {
        return butikApiConfig.get(`/getgameplay/${code}`);
    },
    getGameplayByCode: (code) => {
        return butikApiConfig.get(`/gameplay/${code}`);
    },
    setGameplayGuestPlayer: (code, player, team) => {
        return butikApiConfig.post(`/gameplay/${code}/player`, {
            player: player,
            team: team ? +team : null
        })
    },
    getGameplayStatus: (code) => {
        return butikApiConfig.get(`/gameplay/${code}/status`);
    },
    updateGameplay: (gameplayId, statusId, share) => {
        return butikApiConfig.post("/gameplay/status", {
            gameplayId: +gameplayId,
            statusId: +statusId,
            share: +share
        });
    },
    getCategories: () => {
        return butikApiConfig.get('/categories');
    },
    getGameGroupsForUser: (token, userId) => {
        return butikApiConfig.withAuth(token).get(`/user/${userId}/gamegroups`);
    },
    saveNewGameGroup: (token, userId, groupName) => {
        return butikApiConfig.withAuth(token).post(`/gamegroups`, {
            userId: userId,
            groupName: groupName
        });
    },
    addToGameGroup: (token, userId, groupId, gameId) => {
        return butikApiConfig.withAuth(token).post(`/gamegroups/addgame`, {
            userId: userId,
            groupId: groupId,
            gameId: gameId
        });
    },
    deleteFormGameGroup: (token, userId, groupId, gameId) => {
        return butikApiConfig.withAuth(token).post(`/gamegroups/deletegame`, {
            userId: userId,
            groupId: groupId,
            gameId: gameId
        });
    },
    deleteGameGroup: (token, userId, groupId) => {
        return butikApiConfig.withAuth(token).post(`/gamegroups/deletegroup`, {
            userId: userId,
            groupId: groupId
        });
    },
    postNewMessage: (name, email, message) => {
        return butikApiConfig.post('/newmessage', {
            name: name,
            email: email,
            message: message
        });
    },
    createKlarnaOrder: (token, order, userid, productsIds) => {
        return butikApiConfig.withAuth(token).post('/klarnaorder/neworder', {
            order: order,
            userid: userid,
            productsIds: productsIds
        });
    },
    getKlarnaOrder: (token, order) => {
        return butikApiConfig.withAuth(token).get(`/klarnaorder/${order}`);
    },
    getTeamsForGame: (code) => {
        return butikApiConfig.get(`/gameplay/${code}/teams`);
    },
    checkPlayer: (code, name) => {
        return butikApiConfig.get(`/gameplay/${code}/player/${name}`);
    },
    saveAnswers: (playerid, answer, score) => {
        return butikApiConfig.post('/gameplay/saveanswers', {
            playerid: playerid,
            answer: answer,
            score: score
        })
    },
    deleteUser: (userId, token) => {
        return butikApiConfig.withAuth(token).post(`/user/delete`, {
            userId: userId
        });
    },
    getTeamsScore: (code) => {
        return butikApiConfig.get(`/teamscore/${code}`);
    },
    getCurrentAd: () => {
        return butikApiConfig.get('/marketing/now');
    },
    checkDiscount: (code) => {
        return butikApiConfig.get(`/discount/${code}`);
    },
    resetPassword: (email) => {
        return butikApiConfig.post('/resetpassword', {
            email: email
        });
    },
    changePassword: (email, newpassword, token) => {
        return butikApiConfig.withAuth(token).post(`/changepassword`, {
            email: email,
            newpassword: newpassword
        });
    }
}
