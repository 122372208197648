import React from "react";
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

function ImagesCarousel({images}) {
    return(
        <div className="carousel-container">
            <Carousel images={images} />
        </div>
    );
};

export default ImagesCarousel;