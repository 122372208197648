import React, { useCallback, useContext, useEffect, useState } from "react";
import backgroundPic from  "../../images/background_lek.png";
import { Button, Input, Switch, Spinner, Select, SelectItem, Modal, ModalBody, ModalFooter, useDisclosure, ModalContent } from "@nextui-org/react";
import HeaderComponent from "../Search/HeaderComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { ShopContext } from "../../context/ShopContextProvider";
import { ButikAPI } from "../../apis/ButikAPI";

const pic = {
    backgroundImage: `url(${backgroundPic})`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover"
}

function GuestScreen() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [teams, setTeams] = useState([]);
    const [showTeams, setShowTeams] = useState(false);
    const [showNameDanger, setShowNameDanger] = useState(false);
    const [showCodeDanger, setShowCodeDanger] = useState(false);
    const { player, setPlayer, setIsHost, setShowLoading } = useContext(ShopContext);
    const [showWaiting, setShowWaiting] = useState(false);
    const [saveCode, setSaveCode] = useState(false);
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [showModalMessage, setShowModalMessage] = useState(0);
    const [teamsScore, setTeamsScore] = useState([]);
    const [shareResult, setShareResult] = useState(false);

    useEffect(() => {
        let data;
        if(state !== null)  data = state.data;

        if(data !== null && data !== undefined){
            setPlayer({
                name: data.name,
                code_gameplay: data.code,
                id_player: 0,
                id_team: 0,
                score: 0,
                Answers: null
            });

            setSaveCode(false);
            setIsHost(true);
        }
        // Check if player is null
        else if(player === null)
            resetPlayer();

        if(localStorage.getItem("gameCode") !== null && localStorage.getItem("playerName") !== null)
            setSaveCode(true);

        // If Player exists get the teams
        /*  if(player?.id_player > 0){
            setPlayer({
                ...player,
                name: localStorage.getItem("playerName") !== null ? localStorage.getItem("playerName") : "",
                code_gameplay: localStorage.getItem("gameCode") !== null ? localStorage.getItem("gameCode") : "",
            });
            //fetchTeams(false);
        } */

    }, []);

    const resetPlayer = () => {
        setShowLoading(false);
        setPlayer({
            name: localStorage.getItem("playerName") !== null ? localStorage.getItem("playerName") : "",
            code_gameplay: localStorage.getItem("gameCode") !== null ? localStorage.getItem("gameCode") : "",
            id_player: 0,
            id_team: 0,
            score: 0,
            Answers: null
        });
    }

    /* useEffect(() => {
        if (!showWaiting) {
            return;
        }

        const pollingIntervalId = setInterval(() => {
            fetchGameStatus();
        }, 2500);

        return () => {
            console.log('clear interval')
            clearInterval(pollingIntervalId);
        }
    }, [showWaiting]) */

    const continueAndWait = () => {
        //setShowWaiting(true);
        //fetchGameStatus(player?.code_gameplay);
    };

    const fetchGameStatus = useCallback((code) => {
        setShowLoading(true);
        let promise;
        promise = ButikAPI.getGameplayStatus(code);
        return promise.then(r => r.json()).then(d => {
            if(d.status === "success" ){
                if(d.gameplayStatus === 1){
                    checkPlayer();
                }
                    /* if(player?.id_player === 0 && player?.id_team !== 0)
                        saveNewPlayer(player);
                    else{
                        fetchTeams();
                        // Check if player exists in the database (name + code)
                        checkPlayer(player);
                    } */
                    //startGame();
                else if(d.gameplayStatus === 2){
                    // Visa resultat
                    setShowWaiting(false);
                    fetchResultat(d.code);
                }
                else{
                    setShowWaiting(false);
                    setShowLoading(false);
                    setShowModalMessage(1);
                    onOpen();
                }
            }
            else{
                setShowWaiting(false);
                setShowLoading(false);
                setShowModalMessage(1);
                onOpen();
            }
        })
       /*  return fetch(URL_API + "command=get_status&code=" + player?.code_gameplay)
            .then((res) => res.json())
            .then((d) => {
            if(d){
                console.log(player?.code_gameplay);
                navigate("/gameplay", {state: {data: player?.code_gameplay}})
            }
            else {
                console.log(d);
            }
         }); */
    }, []);

    const fetchResultat = (id) => {
        const code = id !== undefined ? id : player.code_gameplay;
        return ButikAPI.getTeamsScore(code).then(r => r.json())
            .then(d => {
                setShowLoading(false);
                if (d.status === "success") {
                    console.log(d);
                    setTeamsScore(d.buildblocks.teams);
                    setShareResult(d.buildblocks.share_score);
                    setShowModalMessage(2);
                    onOpen();
                }
                else {
                    console.log(d)
                }
            })
    }

    const setNewPlayerName = (e) => {
        setPlayer({
            ...player,
            name: e,
            id_player: 0,
            id_team: 0,
            score: 0,
            Answers: null
        })
    }


    const setNewPlayerCode = (e) => {
        setPlayer({
            ...player,
            code_gameplay: e,
            id_player: 0,
            id_team: 0,
            score: 0,
            Answers: null
        })
    }

    const handleChange = (e) => {
        setPlayer({
            ...player,
            id_team: e.target.value
        });
    }

    const handleClick = () => {
        if (player?.name === "") {
            setShowNameDanger(true);
        }
        else {
            setShowNameDanger(false);
            if (saveCode) localStorage.setItem("playerName", player?.name);
            else localStorage.removeItem("playerName");
        }

        if(player?.code_gameplay === "")
            setShowCodeDanger(true);
        else {
            setShowCodeDanger(false);
            if(saveCode) localStorage.setItem("gameCode", player?.code_gameplay);
            else localStorage.removeItem("gameCode");
        }

        if(player?.name !== "" && player?.code_gameplay !== ""){
            //check game
            fetchGameStatus(player?.code_gameplay);

        }
    }

    const saveNewPlayer = useCallback((item) => {
        let promise;
        promise = ButikAPI.setGameplayGuestPlayer(item.code_gameplay, item.name, item.id_team);
        return promise.then(r => r.json()).then(d => {
            if(d.status === "success"){
                setPlayer(d.player);
                startGame();
            }
            else{
                console.log(d);
            }
        })
    })

    const checkPlayer = useCallback(() => {
        let promise;
        const code = document.getElementById("code").value;
        const name = document.getElementById("name").value;
        promise = ButikAPI.checkPlayer(code,name);
        return promise.then(r => r.json()).then(d => {
            setShowLoading(false);
            if(d.status === "success"){
                if(d.player !== null){
                    setPlayer(d.player);
                    setShowModalMessage(0);
                    onOpen();
                }
            }
            else if(d.status === "teams"){
                setTeams(d.teams);
                setShowTeams(true);
            }
            else if(d.status === "newplayer"){
                setPlayer(d.player);
                saveNewPlayer(d.player);
                //continueAndWait();
            }
            else{
                console.log(d);
            }
        })
    }, [])

    const fetchTeams = useCallback(() => {
        let promise;
        promise = ButikAPI.getTeamsForGame(player?.code_gameplay);
        return promise.then(r => r.json()).then(d => {
            if(d.status === "success"){
                setTeams(d.teams);
            }
            else{
                console.log(d);
            }
        })
    }, [])

    const startGame = () =>{
        navigate("/gameplay");
    }


    const handleCancel = () => setShowWaiting(false);

    return (
        <div className="absolute inset-0 z-50" style={pic}>
            <HeaderComponent header="Gäst" goBackScreen="/playgame" />

        <div className="p-2 pt-20">
            <div style={{color: "504459"}} className="p-5 h-full rounded-2xl shadow-lg p-2 bg-white bg-opacity-40">
                <div className="grid grid-cols-1 gap-5">
                    <Input
                        label="Namn"
                        id="name"
                        value={player?.name}
                        onValueChange={setNewPlayerName}
                        color={showNameDanger ? "danger" : "default"}/>
                    <Input
                        label="Kod"
                        id="code"
                        value={player?.code_gameplay}
                        onValueChange={setNewPlayerCode}
                        color={showCodeDanger ? "danger" : "default"}/>

                    <div className="flex justify-between">
                        <p className="text-medium">Spara referenser till senare</p>
                        <Switch isSelected={saveCode} onValueChange={setSaveCode}/>
                    </div>

                        {showTeams ?
                            <Select
                                className="max-w-xs"
                                label="Välj ditt lag:"
                                onChange={handleChange}>
                                {teams?.map((team) => (
                                    <SelectItem key={team.id_team} value={team.id_team}>
                                        {team.name}
                                    </SelectItem>
                                ))}
                            </Select> : ""}

                    <Button
                        color="primary"
                        onClick={handleClick}>
                        {player?.id_player > 0 ? "Spela" : "Klar"}
                    </Button>
                </div>
                {showWaiting ?
                <div className="inset-0 absolute bg-gray-400 bg-opacity-80 z-50 flex justify-center items-center">
                    <div className="grid grid-cols-1 gap-5">
                        <Spinner label="Väntar på spelet att starta" />

                        <Button
                            color="danger"
                            onClick={handleCancel}>
                            Avbryt
                        </Button>
                    </div>
                </div> : ""}
            </div>
        </div>

        <Modal
            placement="center"
            isOpen={isOpen}
            onOpenChange={onOpenChange}>
            <ModalContent>
            {(onClose) => (
                <>
                {
                {
                    0  :<>
                            <ModalBody>
                                <span>Spelaren redan finns. Vill du att tidigare svar ska laddas ner och fortsätta spela eller välja nytt namn?</span>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="light" variant="light" onPress={() => {resetPlayer(); onClose();}}>
                                    Välj nytt namn
                                </Button>
                                <Button variant="light" onPress={() => {startGame(); onClose();}}>
                                    Spela
                                </Button>
                            </ModalFooter>
                        </>,
                    1  : <>
                            <ModalBody>
                                <span>Det finns ingen pågående lek med kod {player?.code_gameplay}. Försök igen efter lekledaren har startat leken eller med ny kod.</span>
                            </ModalBody>
                            <ModalFooter>
                                <Button variant="light" onPress={onClose}>
                                    OK
                                </Button>
                            </ModalFooter>
                        </>,
                    2   : <>
                                <ModalBody>
                                    <span className="font-bold">Leken är redan slut.</span>
                                    {shareResult ?
                                        <div className="grid grid-cols-2 gap-5">
                                            {teamsScore?.map((team) =>
                                                <div key={team.name}>
                                                <span className="font-bold">Total {team.name} : {team.players_score}</span>
                                                    {team.players?.map((player) =>
                                                        <div><span>{player.name} : {player.score}</span></div>)}

                                                </div>
                                            )}
                                        </div> :
                                        <span>Lekledaren har valt att inte dela resultaten.</span>}
                                </ModalBody>
                                <ModalFooter>
                                    <Button variant="light" onPress={onClose}>
                                        OK
                                    </Button>
                                </ModalFooter>
                            </>
                    }[showModalMessage]
                }

                </>
            )}
            </ModalContent>
        </Modal>
    </div>
    )
}

export default GuestScreen;
