import { isDev } from "../../util/isDev";

// Types
type Headers = {
    [key: string]: string;
};
type Method = "GET" | "POST" | "PUT" | "DELETE";
type Query = { [key: string]: any } | Record<string, string> | undefined;
interface ApiOptions {
    baseUrl: string;
    defaultHeaders?: Headers;
    useCsrf?: boolean;
}

// Base url
export const baseUrl = isDev()
    ? "http://localhost:8080/api"
    : "https://www.pimparty.se/api";

// Initialize csrfToken
let csrfToken: string | null = null;
let csrfPromise: Promise<void> = fetch(`${baseUrl}/csrf/token`, {
    credentials: "include",
})
    .then((r) => r.json())
    .then((res) => {
        csrfToken = res.token;
    });

// API config creator
const ApiCreator = (options: ApiOptions) => {
    const stub = async (
        path: string,
        method: Method,
        body: any,
        query: Query
    ) => {
        const headers: Headers = options.defaultHeaders || {};
        const requestOptions: RequestInit = {
            method: method,
            mode: "cors",
            credentials: "include",
        };

        if (method === "POST" || method === "PUT") {
            headers["Content-Type"] = "application/json";
            requestOptions.body = JSON.stringify(body);
        }

        if (isDev()) {
            if (!query) query = {};
            query["t"] = new Date().getTime();
        }

        if (options.useCsrf === true) {
            if (csrfToken === null) {
                await csrfPromise;
            }

            headers["X-CSRF-Token"] = csrfToken as string;
        }

        requestOptions.headers = headers;
        if (isDev()) {
            console.groupCollapsed(
                `${requestOptions.method} ${
                    options.baseUrl
                }${path}?${new URLSearchParams(query)}`
            );
            console.log("Request options:\n", requestOptions);
            console.groupEnd();
        }

        return fetch(
            `${options.baseUrl}${path}?${new URLSearchParams(query)}`,
            requestOptions
        );
    };

    const get = async (path: string, query: Query) =>
        stub(path, "GET", undefined, query);

    const post = async (path: string, body: any, query: Query) =>
        stub(path, "POST", body, query);

    const put = async (path: string, body: any, query: Query) =>
        stub(path, "PUT", body, query);

    const del = async (path: string, query: Query) =>
        stub(path, "DELETE", undefined, query);

    return { get, post, put, del };
};

export const butikApiConfig = (() => {
    const withAuth = (token: string) => {
        return {
            ...ApiCreator({
                baseUrl: baseUrl + "/butik",
                defaultHeaders: {
                    Authorization: token,
                },
                useCsrf: true,
            }),
        };
    };
    return {
        ...ApiCreator({
            baseUrl: baseUrl + "/butik",
            useCsrf: true,
        }),
        withAuth,
    };
})();

export const subscriptionApiConfig = ApiCreator({
    baseUrl: baseUrl + "/subscription",
    useCsrf: false,
});
