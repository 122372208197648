import React, { useState, useEffect } from "react";
import { Button, Card, CardBody } from "@nextui-org/react";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

function Match(props) {
    const [randomAnswers, setRandomAnswers] = useState([]);
    const [selectID, setSelectedID] = useState();
    const selectedStyle = " bg-green-300 bg-opacity-80 rounded-lg shadow-lg p-1 h-20";
    const cancelButton = "shadow relative -right-0.5 ";
    useEffect(() => {
        setRandomAnswers([]);
        //copy data into another array
        let newList = props.games;
        if(newList !== null && newList !== undefined){
            while(newList.length > 0){
                // get a random element and delete it from the new array
                let item = newList[Math.floor(Math.random()*newList.length)];
                newList = newList.filter(exp => exp.id_bb_data !== item.id_bb_data);

                // Check is the question is already answered
                if(!props.answers?.some(exp => exp.id_bb_data === item.id_bb_data)
                || props.answers?.find(exp => exp.id_bb_data === item.id_bb_data).answer === "")
                    // insert the element into randomAnswers
                    setRandomAnswers((prev) => [item.answer, ...prev]);
            }
        }
    }, [])

    const handleSelected = (e) => {
        const currentId = e.target.id > 0 ? e.target.id : e.target.parentElement.id;

        props.games?.map((g) => {
            document.getElementById(g.id_bb_data).className = "line-clamp-3 p-1 ";
            document.getElementById(g.id_bb_data + "_btn").className = cancelButton + " hidden";
        });

        document.getElementById(currentId).className += selectedStyle;

        if(document.getElementById(currentId + "_answer")?.textContent !== '')
            document.getElementById(currentId + "_btn").className = cancelButton;
        setSelectedID(parseInt(currentId));
    }

    const handleClick = (e) => {
        let newAnswer = e.target.textContent;

        // Check if the question was already answered
        let currentAnswer = document.getElementById(selectID + "_answer").textContent;
        const index = currentAnswer.indexOf(":");
        console.log(index);
        //If yes then delete the current answer and return it to randomAnswers
        if(index >= 0){
            const answer = currentAnswer.substring(index + 2);
            if(answer !== "") setRandomAnswers((prev) => [answer, ...prev]);
        }

        // Unite the two texts for the answer
        document.getElementById(selectID + "_answer").textContent  = ": " + newAnswer;
        // delete the item from randomAnswers
        const blab = randomAnswers;
        setRandomAnswers((prev) => prev.filter(i => i !== newAnswer));

        props.changeAnswers({id_bb_data: selectID, answer: newAnswer});

        // Activate cancel button
        document.getElementById(selectID + "_btn").className = cancelButton;
    }

    const changeAnswer = (id) => {
        let currentAnswer = document.getElementById(id + "_answer").textContent;
        const index = currentAnswer.indexOf(":");
        if(index >= 0){
            const answer = currentAnswer.substring(index + 2);
            setRandomAnswers((prev) => [answer, ...prev]);

            currentAnswer = '';
        }
        document.getElementById(id + "_btn").className = cancelButton + " hidden";
        document.getElementById(id + "_answer").textContent = '';
        props.changeAnswers({id_bb_data: id, answer: ""});
    }

    return(
            <Card className=" bg-white bg-opacity-90" shadow="lg">
                <CardBody>
                    <div className="grid grid-cols-2 gap-10">
                        <div className="grid grid-cols-1 gap-4">
                            {props.games?.map((input) =>
                                <div key={input.id_bb_data} className="flex">
                                    <div id={input.id_bb_data} className="line-clamp-3 p-1 h-12 "
                                        onClick={handleSelected}>
                                        <span className="font-bold">{input.question}</span>
                                        <span id={input.id_bb_data + "_answer"}>
                                            {
                                                props.answers?.some(exp => exp.id_bb_data === input.id_bb_data) &&
                                                props.answers?.find(exp => exp.id_bb_data === input.id_bb_data).answer.length > 0 ?
                                                (": " + props.answers?.find(exp => exp.id_bb_data === input.id_bb_data).answer) : ""
                                            }
                                        </span>
                                    </div>
                                    <Button id={input.id_bb_data + "_btn"}
                                        className={cancelButton + " hidden"}
                                        size="sm"
                                        variant="light"
                                        isIconOnly
                                        aria-label="Cancel"
                                        onPress={() => changeAnswer(input.id_bb_data)}>
                                            <CloseOutlinedIcon />
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className="grid grid-cols-1 gap-4">
                            {randomAnswers.map((ra) =>
                                <button className="line-clamp-2 bg-white bg-opacity-80 rounded-lg h-12 shadow-lg"
                                    onClick={handleClick}>
                                    {ra}
                                </button>
                            )}
                        </div>

                    </div>
                </CardBody>
            </Card>
    )
}

export default Match;
