import React from "react";
import HeaderComponent from "../Search/HeaderComponent";
import Terms from "./Terms";

function TermsofUse() {

    return(
        <div className="container h-full fixed inset-x-0.5 top-14">
            <HeaderComponent header="Användarvillkor" goBackScreen="/myaccount"/>
            <div className="p-2 h-full overflow-y-auto">
                <div style={{color: "504459"}} className=" rounded-2xl shadow-lg p-2 pt-5 bg-white bg-opacity-40 pb-20">
                    <Terms />
                </div>
            </div>
        </div>
    );
}

export default TermsofUse;
