import { useContext } from "react";
import { ShopContext } from "../../context/ShopContextProvider";
import { Spinner } from "@nextui-org/react";

function LoadingPage(){
    const { showLoading } = useContext(ShopContext);
    return(
        <>
            {
                showLoading ?
                    <div style={{zIndex: 100}} className="inset-0 absolute bg-gray-50 bg-opacity-80 flex justify-center items-center">
                        <div className="grid grid-cols-1 gap-5">
                            <Spinner size="lg"/>
                        </div>
                    </div>
                : ""
            }
        </>
    )
}

export default LoadingPage;
