import React from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CartIcon from '../components/CartItems/CartIcon';

const Icon = ({ name }) => {
    return (
        <>
            {
                {
                    "Home"  : <HomeOutlinedIcon fontSize="large"/>,
                    "Play"  : <CasinoOutlinedIcon fontSize="large"/>,
                    "Favorites": <FavoriteBorderIcon fontSize="large"/>,
                    "ShoppingCart": <CartIcon/>,
                    "MyAccount": <PersonOutlineOutlinedIcon fontSize="large"/>
                }[name]
            }
        </>
    )
}

export default Icon;