import React, { useContext } from "react";
import { Accordion, AccordionItem, Button, Listbox, ListboxItem } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { ShopContext } from "../../context/ShopContextProvider";
import MenuIcon from "./MenuIcon";

const iconClasses = "text-xl text-default-500 pointer-events-none flex-shrink-0";

const list = [
    { key: "mygames", text : "Mina lekar"},
    { key: "settings", text : "Inställningar"},
    { key: "contact", text : "Kontakta oss"},
    { key: "termsofuse", text : "Användarvillkor"},
    { key: "about", text : "Om appen"},
    { key: "logout", text : "Logga ut"},
]
function AccountMenu(){
    const {setIsLoggedIn, setUserGames} = useContext(ShopContext);
    const navigate = useNavigate();

    const handleAction = (key) =>
    {
        if(key === "logout") {
            setUserGames([]);
            setIsLoggedIn(false);
        }
        else
            navigate("/" + key);

    }
    return(
        <div className="pt-2">
                <Listbox aria-label="User Menu"
                    className="divide-y shadow-small rounded-medium bg-white bg-opacity-40"
                    itemClasses={{
                        base: "p-3 h-20",
                      }}
                    onAction={(key) => handleAction(key)}>
                       {list.map((item) =>
                            <ListboxItem key={item.key}
                                startContent={<MenuIcon name={item.key} />}
                                endContent={<MenuIcon name="end" />}>
                                <p className="font-semibold text-lg text-left">{item.text}</p>
                            </ListboxItem>)
                        }
                </Listbox>
            </div>
    )
}

export default AccountMenu;
