// Block 2
import React, { useEffect } from "react";
import { Button, Card, CardBody } from "@nextui-org/react";

const whiteButtonClass = "bg-white whitespace-pre-line h-auto p-4";

function Quiz(props){
    useEffect(() => {
        console.log(props.answers);
        console.log(props.games);
        setAllButtonsWhite();

        // set yellow if value exists in answers
        if(props.answers !== undefined)
            if(props.answers.id_bb_data === props.games.id_bb_data){
                if(props.answers.answer === props.games.tips_1)
                    document.getElementById("button_1").className += " bg-yellow-200";

                if(props.answers.answer === props.games.tips_x)
                    document.getElementById("button_2").className += " bg-yellow-200";

                if(props.answers.answer === props.games.tips_2)
                    document.getElementById("button_3").className += " bg-yellow-200";

            }

    }, [props.games])

    const handlePress = (e) => {
        setAllButtonsWhite();

        e.target.className += " bg-yellow-200";

        //change answer
        props.changeAnswers({id_bb_data: props.games.id_bb_data, answer: e.target.textContent});

        //go to next question
        if(props.goToNext !== null && props.goToNext !== undefined)
            props.goToNext();
    }

    const setAllButtonsWhite = () => {

        for(let i = 1; i < 4; i++){
            if(document.getElementById("button_" + i)?.className.includes("bg-yellow-200"))
                document.getElementById("button_" + i).className = document.getElementById("button_" + i).className.replaceAll("bg-yellow-200", "");
        }
    }

    return(
        <Card className=" bg-white bg-opacity-90" shadow="lg">
            <CardBody>
                <div className="h-1/2 flex justify-center items-center text-center pt-5 pb-5">
                    <span style={{color: "#504459"}}
                        className="text-xl font-semibold ">
                        {props.games.question}
                    </span>
                </div>
                <div className="grid grid-cols-1 gap-5 pt-2">

                    <Button id="button_1" className={whiteButtonClass} variant="shadow"
                        size="lg" onPress={handlePress}>
                        {props.games.tips_1}
                    </Button>

                    <Button id="button_2"  className={whiteButtonClass} variant="shadow"
                        size="lg" onPress={handlePress}>
                        {props.games.tips_x}
                    </Button>

                    <Button id="button_3"  className={whiteButtonClass} variant="shadow"
                        size="lg" onPress={handlePress}>
                        {props.games.tips_2}
                    </Button>
                </div>
            </CardBody>
        </Card>
    )
}

export default Quiz
