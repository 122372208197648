import React, {useContext} from "react";
import AccountMenu from "../components/AccountItems/AccountMenu";
import { ShopContext } from "../context/ShopContextProvider";
import Login from "../components/Login/Login";
import HeaderComponent from "../components/Search/HeaderComponent";
import "../css/Background.css";

function MyAccount() {
    const{ isLoggedIn } = useContext(ShopContext);

    return(
        <div className="h-screen">
            <HeaderComponent header="Mitt konto" />

            <div className="grid grid-col-1 gap-5 p-2 h-full fixed inset-x-0.5 top-14">
                {isLoggedIn ? <AccountMenu /> : <Login />}
            </div>

        </div>
    )
}

export default MyAccount;
