import React, { useEffect, useState } from "react";
import { Card, CardBody } from "@nextui-org/react";

function Bingo(props){
    const [answers, setAnswers] = useState();
    const boxStyle = "flex justify-center text-center items-center border border-gray-400";
    const lineStyle = "grid grid-cols-5 h-4/5";

    useEffect(() => {
        console.log(props.answers);

        if(props.answers.length === 0)
            setAnswers([[], [], [], [], []]);
        else{
            setAnswers(props.answers);
            for(let i = 0; i < 5; i++)
                if(props.answers[i].length > 0)
                    for(let j = 0; j < props.answers[i].length; j++)
                        if(props.answers[i][j] === document.getElementById(i.toString()+j.toString()).textContent)
                            document.getElementById(i.toString()+j.toString()).className += " bg-yellow-200";
        }
    }, [])

    const handleClick = (e) =>{
        const id = e.target.id.substring(0,1);
        const digit = e.target.id.substring(1,2);

        let wasRowBingo = true;
        let wasColumnBingo = true;

        // Check if it was bingo on row
        for(let i = 0; i < e.target.parentElement.children.length; i++){
            if(!e.target.parentElement.children[i].className.includes("bg-green-300"))
                wasRowBingo = false;
        }

        // Check if it was bingo on column
        for(let i = 0; i <=4; i++){
            if(!document.getElementById(i+digit).className.includes("bg-green-300"))
                wasColumnBingo = false;
        }

        if(e.target.className.includes("bg-yellow-200") || e.target.className.includes("bg-green-300")){
            e.target.className = boxStyle;
            // delete the value from props.answers
            props.changeAnswers(null, -1);
        }
        else {
            e.target.className += " bg-yellow-200";
            console.log(e.target.textContent);
            // add the value to props.answers
            let copyAnswers = answers;
            copyAnswers[parseInt(id)][parseInt(digit)] = e.target.textContent;
            setAnswers(copyAnswers);
            props.changeAnswers(answers, 1);
        }

        //check if bingo on row
        let isRow = true;
        for(let i = 0; i < e.target.parentElement.children.length; i++){
            if(!e.target.parentElement.children[i].className.includes("bg-yellow-200")
                && !e.target.parentElement.children[i].className.includes("bg-green-300"))
                isRow = false;
        }

        if(isRow){
            for(let i = 0; i < e.target.parentElement.children.length; i++)
                e.target.parentElement.children[i].className = boxStyle + " bg-green-300";

            // if bingo on row then +5 points
            props.changeAnswers(null, 5);
        }
        else if(wasRowBingo){
            // Check if bingo on each column
            for(let i = 0; i < e.target.parentElement.children.length; i++){
                let check = true;
                for(let j = 0; j <= 4; j++)
                    if(!document.getElementById(j.toString() + i.toString()).className.includes("bg-green-300"))
                        check = false;

                if(e.target.parentElement.children[i].className.includes("bg-green-300") && !check ){
                    e.target.parentElement.children[i].className = boxStyle + " bg-yellow-200";
                }
            }

             // is it was bingo on row but an answer changed, then 5 points are lost
            props.changeAnswers(null, -5);
        }

        //check if bingo on column
        let isColumn = true;
        for(let i = 0; i <=4; i++){
            if(!document.getElementById(i+digit).className.includes("bg-yellow-200")
                && !document.getElementById(i+digit).className.includes("bg-green-300"))
                isColumn = false;
        }

        if(isColumn){
            for(let i = 0; i <=4; i++)
                document.getElementById(i+digit).className = boxStyle + " bg-green-300"

                // if bingo on column then +5 points
                props.changeAnswers(null, 5);
            }
        else if(wasColumnBingo){
            //Check bingo for each row
            for(let i = 0; i <=4; i++){
                let check = true;
                    for(let j = 0; j <= 4; j++)
                        if(!document.getElementById(i.toString() + j.toString()).className.includes("bg-green-300"))
                            check = false;
                if(document.getElementById(i+digit).className.includes("bg-green-300") &&
                    i+digit !== e.target.id && !check){
                        console.log(i+digit);
                        document.getElementById(i+digit).className = boxStyle + " bg-yellow-200";
                }
            }

                // is it was bingo on column but an answer changed, then 5 points are lost
                props.changeAnswers(null, -5);
        }

        //console.log(e.target);
            //if(document.getElementById(i+"digit").className)
        //console.log("id: " + e.target.id + ", digit: " + digit);
    }

    return(
            <Card className="bg-white bg-opacity-90 text-xs" shadow="lg">
                <CardBody className="p-0">
                        {props.games.map((input, i)=>
                            <div key={input.rad} className={lineStyle}>
                                <button key={input.rad+"0"} id={i + "0"} className={boxStyle} onClick={handleClick}>
                                    {input[0]}
                                </button>

                                <button key={input.rad+"1"} id={i + "1"} className={boxStyle} onClick={handleClick}>
                                    {input[1]}
                                </button>

                                <button key={input.rad+"2"} id={i + "2"} className={boxStyle} onClick={handleClick}>
                                    {input[2]}
                                </button>

                                <button key={input.rad+"3"} id={i+ "3"} className={boxStyle} onClick={handleClick}>
                                    {input[3]}
                                </button>

                                <button key={input.rad+"4"} id={i + "4"} className={boxStyle} onClick={handleClick}>
                                    {input[4]}
                                </button>

                            </div>
                        )}
                </CardBody>
            </Card>
    )
}

export default Bingo;
