import { subscriptionApiConfig } from "./configs/fetchConfig";

export const SubscriptionAPI = {
    getVapidKey: async () => {
        return subscriptionApiConfig.get("/vapid-key", {});
    },
    register: async (subscription: PushSubscription) => {
        return subscriptionApiConfig.post(
            "/register",
            { subscription: subscription },
            {}
        );
    },
    associateUser: async (subscription: PushSubscription, userId: number) => {
        return subscriptionApiConfig.post(
            "/associate-user",
            { subscription: subscription, userId: userId },
            {}
        );
    },
    notificationTelemetry: async (
        subscription: any,
        eventId: number,
        action: string
    ) => {
        return subscriptionApiConfig.post(
            "/notification-telemetry",
            {
                subscription: subscription,
                eventId: eventId,
                action: action,
            },
            {}
        );
    },
};
