import React, { useEffect, useState } from "react";
import { Card, CardBody, Image } from "@nextui-org/react";
import "./Cards.css";
import cardPic from  "../../../images/background.png";
import frontPic from  "../../../images/frontcard.png";

function Cards(props){
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [playCards, setPlayCards] = useState(props.games);
    const [backPic, setBackPic] = useState(props.picture !== null ? props.picture : cardPic);

    const handleTurn = (game) => {
        // the card goes out of screen
        if(game.question === currentQuestion)
            setPlayCards((prev) => prev.filter(exp => exp.id_bb_data !== game.id_bb_data))
        // set the current card
        else
            setCurrentQuestion(game.question);
    }

    return(
        <Card className=" bg-white bg-opacity-90" shadow="lg">
            <CardBody className="flex justify-center items-center text-center">
               {
                    playCards.map((game, i) =>
                    <SingleCard
                        backPic = {backPic}
                        key={ i }
                        game={game}
                        stl={51 + i}
                        flipped={game.question === currentQuestion}
                        handleTurn={handleTurn}/> )
                }
            </CardBody>
        </Card>
    )
}

function SingleCard(props){

    const handleClick = () => {
        props.handleTurn(props.game);
    }

    return(
        <div
            onClick={handleClick}
            className="card h-5/6 w-5/6 flex items-center"
            style={{zIndex: props.stl}}>
            <div className={props.flipped ? "flipped" : ""}>
                <div className="front flex items-center justify-center bg-white">
                    <img
                        className="back"
                        src={frontPic}
                        />
                    <span className="centered w-3/4">{props.game.question}</span>
                </div>
                <img
                    height='600px'
                    width='400px'
                    className="back"
                    src={props.backPic}
                    />
            </div>
        </div>
    )
}

export default Cards;
