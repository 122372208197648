import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { registerServiceWorker } from './util/registerServiceWorker.ts';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);


// TODO: Remove this before merge, this just unregisters the service worker with each
// page load so that we can modify and test the worker without worrying about caching..
registerServiceWorker(false).catch(e => console.error('Error during service worker reg: ', e));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
