import { Button, Modal, ModalBody, ModalContent, Spinner, useDisclosure} from "@nextui-org/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import backgroundPic from "../../images/background_lek.png";
import HeaderComponent from "../Search/HeaderComponent";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import Quiz from "./Blocks/Quiz";
import GuessPic from "./Blocks/GuessPic";
import GuessText from "./Blocks/GuessText";
import Cards from "./Blocks/Cards";
import Quiz1x2 from "./Blocks/Quiz1x2";
import Bingo from "./Blocks/Bingo";
import Match from "./Blocks/Match";
import { useLocation, useNavigate } from "react-router-dom";
import { ShopContext } from "../../context/ShopContextProvider";
import { ButikAPI } from "../../apis/ButikAPI";

function GamePlayScreen() {
    const { state } = useLocation();
    //const { data } = state;
    const navigate = useNavigate();
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [index, setIndex] = useState(0);
    const [gameIndex, setGameIndex] = useState(0);
    const [currentGame, setCurrentGame] = useState();
    const [currentPic, setCurrentPic] = useState(backgroundPic);
    const [gamePlayData, setGamePlayData] = useState(null);
    const [userAnswers, setUserAnswers] = useState([]);
    const [currentAnswer, setCurrentAnswer] = useState();
    const [gameplayDetails, setGameplayDetails] = useState();
    const [gameName, setGameName] = useState('Spel');
    const [showFinish, setShowFinish] = useState(false);
    const [cardPic, setCardPic] = useState(backgroundPic);
    const { setShowLoading, player, setPlayer, URL_MEDIA, isHost } = useContext(ShopContext);

    const pic = {
        backgroundImage : 'url(' + currentPic + ')',
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover"
    }

    useEffect(() => {
        if(gamePlayData === null)
            onOpen();
            fetchGamePlay();
    }, []);

    const fetchGamePlay = async () => {
        console.log(isHost);
        return ButikAPI.getGameplayById(player?.id_gameplay)
       // return fetch(URL_API + "command=get_gameplay&code=" + data)
        .then((res) => res.json())
        .then((d) => {
            setShowLoading(false);
            if(d.status === "success"){
                console.log(d.buildblocks);
                setGamePlayData(d.buildblocks);
                setGameplayDetails(d.details);
                setGameName(d.details.name)
                if(player?.Answers !== undefined && player?.Answers !== null) {
                    const answers = JSON.parse(player.Answers);
                    setUserAnswers(answers);
                    const lastAnsweredGame = answers[answers.length-1];
                    const pos = d.buildblocks.findIndex(exp => exp.gameid === lastAnsweredGame.gameid);
                    setGameIndex(pos);
                    setCurrentGame(d.buildblocks[pos]);

                    if(lastAnsweredGame !== null && lastAnsweredGame !== undefined && lastAnsweredGame.data.length > 0){
                        const lastAnsweredQuestion = lastAnsweredGame.data[lastAnsweredGame.data.length - 1];
                        const pos2 = d.buildblocks[pos].data.findIndex(exp => exp.id_bb_data === lastAnsweredQuestion.id_bb_data);

                        setSpeciaIndex(d.buildblocks[pos].gameType, pos2);
                        setCurrentAnswer(lastAnsweredQuestion);
                    }
                    else
                        setSpeciaIndex(d.buildblocks[pos].gameType);

                    changeBackground(d.buildblocks[pos].background, d.details.game_background, d.details.id_game, d.buildblocks[pos].gameType);
                }
                else
                {
                    changePage(d.buildblocks, gameIndex, 0, d.details);
                }
           }
            else
                console.log(d.buildblocks);
        });
    };

    const setSpeciaIndex = (gameType, i) => {
        if (gameType === "block_2")
            setIndex(i);
        else
            setIndex(-1);

        /* if(gameType === "block_3" ||
            gameType === "block_4" ||
            gameType === "block_5" ||
            gameType === "block_6" ||
            gameType === "block_7")
                setIndex(-1);
        else
                setIndex(i); */
    }

    const closeGame = () => {
        navigate("/guestscreen");
    }

    const handleNextGame = () => {
        if (gameIndex + 1 === gamePlayData.length){
            setShowFinish(true);
            onOpen();
            //navigate("/guestscreen");
        }
        else {
            if(gamePlayData[gameIndex+1].data === null || gamePlayData[gameIndex+1].data === undefined){
                setSpeciaIndex(gamePlayData[gameIndex+2].gameType, 0);
                changePage(gamePlayData, gameIndex+2, 0, gameplayDetails);
            }
            else{
                setSpeciaIndex(gamePlayData[gameIndex+1].gameType, 0);
                changePage(gamePlayData, gameIndex+1, 0, gameplayDetails);
            }
        }
    }

    const handleLastGame = () => {
        if (gameIndex === 0) navigate("/guestscreen");
        else {
            if(gamePlayData[gameIndex-1].data === null || gamePlayData[gameIndex-1].data === undefined){
                setSpeciaIndex(gamePlayData[gameIndex-2].gameType, gamePlayData[gameIndex-1].data.length-1);
                changePage(gamePlayData, gameIndex-2, gamePlayData[gameIndex-1].data.length-1, gameplayDetails);
            }
            else {
                setSpeciaIndex(gamePlayData[gameIndex-1].gameType, gamePlayData[gameIndex-1].data.length-1);
                changePage(gamePlayData, gameIndex-1, gamePlayData[gameIndex-1].data.length-1, gameplayDetails);
            }
        }
    }

    const changeBackground = (newBackground, defaultBackground, id, gameType) =>{
        let picString = URL_MEDIA + id + "/";
        if(gameType === 'block_6'){
            setCurrentPic('');
            if(newBackground !== null && newBackground !== undefined && newBackground !== "")
                setCardPic(picString+newBackground);
            else if(defaultBackground !== null && defaultBackground !== undefined && defaultBackground !== "")
                setCardPic(picString+defaultBackground);
            else
            setCardPic(backgroundPic);
            return;
        }

        if(newBackground !== null && newBackground !== undefined && newBackground !== "")
            setCurrentPic(picString+newBackground);
        else if(defaultBackground !== null && defaultBackground !== undefined && defaultBackground !== "")
            setCurrentPic(picString+defaultBackground);
        else
            setCurrentPic(backgroundPic);
    }

    const changePage = (gameChange, indexChange, i, details) => {
        setCurrentGame(gameChange[indexChange]);
        setGameIndex(indexChange);
        changeBackground(gameChange[indexChange].background, details.game_background, details.id_game, gameChange[indexChange].gameType);

        if(!userAnswers.some(i => {return i.gameid === gameChange[indexChange].gameid})){
            const newItem = {gameid : gameChange[indexChange]?.gameid, data : []};
            setUserAnswers([...userAnswers, newItem]);
        }
        else
            setCurrentAnswer(userAnswers.find(exp => exp.gameid === gameChange[indexChange]?.gameid).data.find(exp => exp.id_bb_data === gameChange[indexChange].data[i].id_bb_data));
    }

    const changeAnswers = (item, points) => {
        let score = player.score;
        let data = "";
        let newUserAnswers = userAnswers;

        if (Array.isArray(item)) {
            newUserAnswers = userAnswers.map(exp => {
                if (exp.gameid === currentGame.gameid) {
                    return {
                        ...exp,
                        data: item
                    };
                }
                else return exp;
            })
        }
        else if (item !== null && item !== undefined) {
            //const correctAnswer = currentGame.data[index];
            const correctAnswer = currentGame.data.find(exp => exp.id_bb_data === item.id_bb_data);

            setCurrentAnswer(item);
            // if the new answers is correct then +1
            if (item?.answer === correctAnswer?.answer)
                score += 1;

            // if data doesn't have any value then add a new one
            if (userAnswers[gameIndex].data.some(exp => exp.id_bb_data === item.id_bb_data)) {
                const oldAnswer = userAnswers[gameIndex].data.find(exp => exp.id_bb_data === item.id_bb_data);

                // if the new answer if wrong but the old one was correct -1
                if (item.answer !== correctAnswer.answer && oldAnswer?.answer === correctAnswer.answer)
                    score -= 1;

                data = userAnswers[gameIndex].data.map(exp => {
                    if (exp.id_bb_data === item.id_bb_data)
                        return item;
                    else return exp;
                });
            }
            else
                data = [...userAnswers[gameIndex].data, item];

            newUserAnswers = userAnswers.map(exp => {
                if (exp.gameid === currentGame.gameid) {
                    return {
                        ...exp,
                        data: data
                    };
                }
                else return exp;
            })

        }

        if (points !== null && points !== undefined)
            score += points;

        setUserAnswers(newUserAnswers);
        setPlayer({
            ...player,
            score: score,
            Answers: JSON.stringify(newUserAnswers)
        });
        saveAnswers(JSON.stringify(newUserAnswers), score);
    }

    const saveAnswers = useCallback((a,s) => {
        return ButikAPI.saveAnswers(player.id_player, a, s)
            .then(r => r.json())
            .then(d => {
                console.log(d);
            });
    }, [])

/*     const saveAnswers = (a, s) => {
        const requestOptions = {
            method: 'POST',
            mode: 'cors',
            header: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id_player: player.id_player,
                answers: a === null ? player.Answers : a,
                score: s
            })
        };

        //console.log(requestOptions);

        return fetch(URL_API + "command=save_answers", requestOptions)
            .then((res) => res.json())
            .then((d) => {
            if(d){
                console.log(d);
            }
            else {

            }
        });
    } */

    const handlePrevQuestion = () => {
        setIndex(index - 1);
        setCurrentAnswer(userAnswers[gameIndex].data.find(exp => exp.id_bb_data === currentGame?.data[index - 1].id_bb_data));

    }

    const handleNextQuestion = () => {
        setIndex(index + 1);
        setCurrentAnswer(userAnswers[gameIndex].data.find(exp => exp.id_bb_data === currentGame?.data[index + 1].id_bb_data));
    }

    const getAnswers = () => {
        let answer = "<div>" + currentGame?.info + "</div>";
        if(currentGame?.gameType === 'block_3' && isHost){
            currentGame?.data.map(exp => answer += "<div>" +  exp.question + ": " + exp.answer + "</div>");
        }

        return answer;
    }

    const goToNext = () => {
        if(index === currentGame?.data?.length - 1 || index === -1 )
            handleNextGame();
        else
            handleNextQuestion();
    }

    return(
    <div className="absolute fixed inset-0 z-50 h-screen w-screen" style={pic}>
        <HeaderComponent header={gameName} goBackScreen="/guestscreen" info={getAnswers()}/>

            <div className="p-2 grid grid-cols-1 bottom-16 fixed inset-x-0.5 top-20">
                {
                    {
                        "block_1": <GuessPic games={currentGame?.data} index={index} idGame={gameplayDetails?.id_game}/>,
                        "block_2": <Quiz games={currentGame?.data[index]} answers={currentAnswer} changeAnswers={changeAnswers} goToNext={goToNext}/>,
                        "block_3": <GuessText games={currentGame?.data} answers={userAnswers[gameIndex]?.data} changeAnswers={changeAnswers}/>,
                        "block_4": <Bingo games={currentGame?.data} answers={userAnswers[gameIndex]?.data} changeAnswers={changeAnswers} />,
                        "block_5": <Match games={currentGame?.data} answers={userAnswers[gameIndex]?.data} changeAnswers={changeAnswers} />,
                        "block_6": <Cards games={currentGame?.data} picture={cardPic}/>,
                        "block_7": <Quiz1x2 games={currentGame?.data} answers={userAnswers[gameIndex]?.data} changeAnswers={changeAnswers} />
                    }[currentGame?.gameType]
                }
            </div>
            <div className="fixed bottom-0 inset-x-0">
            <div style={{ color: "#504459" }}
                className="flex justify-between text-black px-5 pb-5">
                {(index === 0 || index === -1) ?
                    <Button
                        className="shadow-sm bg-white bg-opacity-90 text-lg"
                        startContent={<ArrowBackIosNewOutlinedIcon />}
                        onClick={handleLastGame}>
                        Gå tillbaka
                    </Button> :
                    <Button
                        className="shadow-sm bg-white bg-opacity-90 text-lg"
                        startContent={< ArrowBackIosNewOutlinedIcon />}
                        onClick={handlePrevQuestion}>
                        Före
                    </Button>
                }

                {(index === currentGame?.data?.length - 1 || index === -1 ) ?
                <Button
                    className="shadow-sm bg-white bg-opacity-90 text-lg"
                    endContent={<ArrowForwardIosOutlinedIcon />}
                    onClick={handleNextGame}>
                    Gå vidare
                </Button>:
                <Button
                    className="shadow-sm bg-white bg-opacity-90 text-lg"
                    endContent={<ArrowForwardIosOutlinedIcon />}
                    onClick={handleNextQuestion}>
                    Nästa
                </Button>
                }
            </div>
            </div>
        <Modal
              size="full"
              isOpen={isOpen}
              onOpenChange={onOpenChange}
              >
              <ModalContent>
                {(onClose) => (
                  <>
                    <div className="w-full h-full">
                      <img
                        alt="gamestart"
                        className="w-full object-cover h-full inset-0 absolute"
                        src={gamePlayData === null ? backgroundPic : URL_MEDIA + gameplayDetails.id_game + "/" + gameplayDetails.title_picture}
                      />
                      {showFinish ?
                        <div className="flex items-center bg-white bg-opacity-80 absolute inset-0">
                            <div style={{color: "504459"}} className="flex text-center items-center justify-center flex-col p-2 text-xl font-semibold">
                                <span>Tack för att du var med och spelade {gameName}!</span>
                                <span>När spelet tar slut kan man se resultaten om lekledaren har valt att dela den.</span>
                                <div className="w-full pt-8">
                                <Button style={{backgroundColor: "#504459", width: "100%"}}
                                    className="h-14 text-xl text-white shadow-lg"
                                    variant="flat"
                                    color="danger"
                                    aria-label="Buy"
                                    onPress={closeGame}>
                                        Stäng
                                </Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="absolute bottom-20 left-0 right-0">
                            <div className="grid grid-cols-1 p-2">
                                {gamePlayData === null ?
                                    <Spinner label="Laddar" color="default" labelColor="foreground"/>
                                    :
                                    <Button style={{backgroundColor: "#504459"}}
                                        className="h-14 text-xl text-white shadow-lg"
                                        variant="flat"
                                        color="danger"
                                        aria-label="Buy"
                                        onPress={onClose}>
                                                {player?.Answers !== null ? 'Fortsätt' : 'Starta spel'}
                                    </Button>
                                }
                            </div>
                      </div>
                    }
                      </div>
                  </>
                )}
              </ModalContent>
            </Modal>
    </div>
    )
}

export default GamePlayScreen;
