import React, { useCallback, useContext, useEffect, useState } from "react";
import backgroundPic from "../../images/background_lek.png";
import { Button, Input, Switch, Spinner, Select, SelectItem, Accordion, AccordionItem } from "@nextui-org/react";
import HeaderComponent from "../Search/HeaderComponent";
import { ShopContext } from "../../context/ShopContextProvider";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useLocation, useNavigate } from "react-router-dom";
import { ButikAPI } from "../../apis/ButikAPI";

const pic = {
    backgroundImage: `url(${backgroundPic})`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover"
}

function HostStartGame() {
    const { state } = useLocation();
    const { data } = state;
    const [gameplay, setGameplay] = useState(data);
    const [isVisible, setIsVisible] = useState(false);
    const [teamsScore, setTeamsScore] = useState([]);
    const [teams, setTeams] = useState([{ id_team: 1, name: "Lag 1" }, { id_team: 2, name: "Lag 2" }]);
    const [plusButton, setPlusButton] = useState(true);
    const [isSaved, setIsSaved] = useState();
    const { appUser, userToken, setShowLoading } = useContext(ShopContext);
    const navigate = useNavigate();

    const handleCreateTeams = () => {
        if (!isVisible) {
            setTeams([{ id_team: 1, name: "Lag 1" }, { id_team: 2, name: "Lag 2" }]);
            setPlusButton(true);
        }

        setIsVisible(!isVisible);
    }

    const onTeamsChange = (value, id) => {
        if (teams !== null) {
            const newTeams = teams?.map(team => {
                if (team.id_team === id)
                    return { ...team, name: value };
                else
                    return team;
            });
            console.log(newTeams);
            setTeams(newTeams);
        }
    }

    const addNewTeam = () => {
        setTeams([...teams, { id_team: 3, name: "Lag 3" }]);
        setPlusButton(false);
    }

    const fetchGamePlay = (id) => {
        const code = id > 0 ? id : gameplay.code_gameplay;
        return ButikAPI.getTeamsScore(code).then(r => r.json())
            .then(d => {
                if (d.status === "success") {
                    console.log(d);
                    setTeamsScore(d.buildblocks.teams);
                }
                else {
                    console.log(d)
                }
            })
    }

    useEffect(() => {
        console.log(calcHeight);
        //console.log(data[0].id_gameplay);
        if (data?.id_gameplay === undefined)
            setIsSaved(false);
        else {
            setIsSaved(true);
            fetchGamePlay(data.code_gameplay);
        }
    }, []);

    const saveGameplay = () => {
        setShowLoading(true);
        return ButikAPI.saveGameplay(
            userToken,
            gameplay.code_gameplay,
            gameplay.id_game,
            gameplay.id_user,
            gameplay.id_status,
            gameplay.share_score ? 1 : 0,
            isVisible ? teams : [{ id_team: 1, name: "Alla" }]
        ).then(r => r.json()).then(d => {
            setShowLoading(false);
            if (d.status === "success") {
                console.log(d);
                setGameplay(d.gameplay);
                setTeams(d.gameplay.teams);
                fetchGamePlay(d.gameplay);
                setIsSaved(true);
            } else {
                console.log(d);
            }
        });
    }

    const startGameplay = () => {
        setGameplay({
            ...gameplay,
            id_status: 1
        });
        updateGamePlay(1);
    }

    const stopGamePlay = () => {
        setGameplay({
            ...gameplay,
            id_status: 2
        });
        updateGamePlay(2);
    }

    const updateGamePlay = (status) => {
        return ButikAPI.updateGameplay(
            gameplay.id_gameplay,
            status,
            gameplay.share_score ? 1 : 0
        ).then(r => r.json()).then(d => {
            if (d.status === "success") {
                console.log(d)
               /*  setGameplay({
                    ...gameplay,
                    id_status: 1
                }); */
            } else {
                console.log(d)
            }
        });
    }

    const goToPlay = () =>{
        navigate("/guestscreen", { state: { data: {name: appUser.username, code: data.code_gameplay}} });
    }

    const calcHeight = (window.innerHeight - 400).toString() + 'px';

    return (

        <div className="absolute inset-0 z-50" style={pic}>
            <HeaderComponent header="Starta lek" goBackScreen="/hostscreen" />

            <div className="fixed inset-x-2.5 top-16 grid grid-cols-1 gap-2">
                <div style={{ color: "504459" }} className=" h-full rounded-2xl shadow-lg p-2 bg-white bg-opacity-40 text-xl font-semibold">

                    <div className="grid grid-cols-1 gap-5">
                        <div className="flex justify-between">
                            <p>Status: </p>
                            {{
                                0: <p>Ny</p>,
                                1: <p>Pågående</p>,
                                2: <p>Slut</p>
                            }[gameplay?.id_status]
                            }
                        </div>

                        <div className="flex justify-between">
                            <p>Koden är:</p>
                            <p>{gameplay?.code_gameplay}</p>
                        </div>

                        <div className="flex justify-between">
                            <p>Dela resultat</p>
                            {
                                gameplay?.id_status === 0 ?
                                    <Switch isSelected={gameplay?.share_score}
                                        onValueChange={(value) => setGameplay({ ...gameplay, share_score: value })} />
                                    :
                                    <Switch isSelected={gameplay?.share_score} isDisabled />
                            }
                        </div>

                        {(isSaved && gameplay.id_status == 1) ?
                            <Button
                                className="bg-white"
                                variant="shadow"
                                size="lg"
                                onPress={goToPlay}>
                                Var med själv
                            </Button>
                            : ""
                        }

                        {/*  <Button>Se resultat i modal?</Button> */}


                        {!isSaved  ?
                            <>
                                <div id="teamsDiv" className={isVisible ? "border-t-1 border-b-1 border-gray-400 pb-1 pt-1" : "hidden"}>
                                    <div className="pb-1">
                                        {teams.map((team) =>
                                            <Input variant="underlined" value={team.name}
                                                onValueChange={(value) => onTeamsChange(value, team.id_team)} />)
                                        }
                                    </div>
                                    <div className="flex justify-end">
                                        <Button radius="full"
                                            isIconOnly
                                            className={plusButton ? "bg-white" : "hidden"}
                                            variant="shadow"
                                            onPress={addNewTeam}>
                                            <AddOutlinedIcon />
                                        </Button>
                                    </div>
                                </div>
                                <Button
                                    className="bg-white"
                                    variant="shadow"
                                    size="lg"
                                    onPress={handleCreateTeams}>
                                    {isVisible ? "Ta bort lag" : "Skapa lag"}
                                </Button>

                                <Button
                                    className="bg-white"
                                    variant="shadow"
                                    size="lg"
                                    onPress={saveGameplay}>
                                    Klar
                                </Button>
                            </> : ""
                        }
                    </div>
                </div>
                {isSaved ?
                    <div style={{ color: "504459" }}
                        className={gameplay?.id_status == 1 ?
                            "p-2 rounded-2xl shadow-lg bg-white bg-opacity-40 text-xl font-semibold fixed inset-2.5 top-72"
                            : "p-2 rounded-2xl shadow-lg bg-white bg-opacity-40 text-xl font-semibold fixed inset-2.5 top-56"}>
                    <div style={{height: "100%"}} className=" overflow-auto flex flex-col">
                         <Accordion selectionMode="multiple"
                                    style={{ height: calcHeight, overflow: 'scroll'}} >
                            {teamsScore?.map((team) =>
                                <AccordionItem
                                    key={team.id_team}
                                    aria-label="Team"
                                    title={team.name + "  " + team.players_count + " spelare " + team.players_score + " poäng"}>
                                    {team.players?.map((player) =>
                                        <div><span>{player.name} : {player.score}</span></div>)}

                                </AccordionItem>
                            )}
                        </Accordion>
                        <div className="absolute bottom-0 left-0 right-0 pt-5">
                             <div className="flex justify-between p-2">
                                <Button
                                    className="bg-white w-2/5"
                                    variant="shadow"
                                    size="lg"
                                    onPress={fetchGamePlay}>
                                    Ladda om
                                </Button>
                                {{
                                    0: <Button
                                        className="bg-white w-2/5"
                                        variant="shadow"
                                        size="lg"
                                        onPress={startGameplay}>
                                        Starta leken
                                    </Button>,
                                    1: <Button
                                        className="bg-white w-2/5"
                                        variant="shadow"
                                        size="lg"
                                        onPress={stopGamePlay}>
                                        Stop
                                    </Button>
                                }[gameplay?.id_status]
                                }
                            </div>
                        </div>
                    </div>
                    </div>
                : ""}
            </div>
        </div>
    )
}

export default HostStartGame;
