import React, {useState, useEffect, useContext} from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";
import Icon from "./Icons";
import './Navigation.css';
import { ShopContext } from "../context/ShopContextProvider";

const tabbar = [
    {name: "Home", route: "/pim", position: 0},
    {name: "Favorites", route: "/favoritegames", position: 1},
    {name: "Play", route: "/playgame", position: 2},
    {name: "ShoppingCart", route: "/shoppingcart", position: 3},
    {name: "MyAccount", route: "/myaccount", position: 4},
]

function BottomNavigation2(props) {
    const [selected, setSelected] = useState();
    const [transparentCircleStart, setTransparentCircleStart] = useState(0);
    const [indicatorStart, setIndicatorStart] = useState(0);
    const navigate = useNavigate();
    const{ displayMenu } = useContext(ShopContext);

    const bottomBar = {
        backgroundImage: "radial-gradient(circle at " + transparentCircleStart + "px 5px, transparent 28px, #ffffff 29px)",
    }

    const navIndicator = {
        left: indicatorStart + "px",
    }

    const importantWidth = (window.innerWidth - 16) / 10 + 8;
    const distance =  (window.innerWidth - 16) / 5;

    useEffect(() => {
        if(props.startPage !== null && props.startPage !== undefined)
            changePage(props.startPage);
        else {
            navigate("/pim");
            setSelected("Home");
            //(window width - padding)/tabbar.count/2 - padding/2
            setTransparentCircleStart(importantWidth);
            //transparentCircleStart - indicator width/2
            setIndicatorStart(importantWidth - 24);
        }

    }, [props.startPage]);

    const changePage = (menuItem) => {
        setTransparentCircleStart(importantWidth + distance * menuItem.position);
        setIndicatorStart(importantWidth + distance * menuItem.position - 24);
        setSelected(menuItem.name);
        navigate(menuItem.route);

    }

    return(
        <div style={{display: displayMenu}}>
            <div style={bottomBar} className="sc-bottom-bar h-16 fixed bottom-0 left-0 right-0 z-30  p-1 px-2">
                <div className="grid grid-cols-5 place-items-center rounded-xl">
                    {tabbar.map((menuItem, index) =>
                        <Button key={index} className={(selected === menuItem.name) ? "sc-menu-item sc-current" : "sc-menu-item"}
                            isIconOnly   variant="light"
                            size="lg"
                            aria-label="ShoppingCart" onClick={() => changePage(menuItem)}>
                            <Icon name={menuItem.name} />
                        </Button>
                    )}
                <a className="sc-nav-indicator" style={navIndicator}>
			    </a>
            </div>
            </div>
        </div>
    )
}

export default BottomNavigation2;
