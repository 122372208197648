import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import backgroundPic from "../../images/background_lek.png";
import { Button, Input, Switch, Spinner, Select, SelectItem}  from "@nextui-org/react";
import HeaderComponent from "../Search/HeaderComponent";
import { ShopContext } from "../../context/ShopContextProvider";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useLocation, useNavigate } from "react-router-dom";
import "./StatusCircle.css";
import { ButikAPI } from "../../apis/ButikAPI";
import axios from "axios";
import { isDev } from "../../util/isDev";

const pic = {
    backgroundImage: `url(${backgroundPic})`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "cover"
}

function HostScreen() {
    const { state } = useLocation();
    const [selectedGame, setSelectedGame] = useState([]);
    const {appUser, userGames, setUserGames, setShowLoading, userToken, setDisplayMenu} = useContext(ShopContext);
    const [gameHistory, setGameHistory] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [selected, setSelected] = useState([]);
    const navigate = useNavigate();
    const [currentId, setCurrentId] = useState();
    const [currentGame, setCurrentGame] = useState('');

  const iframeRef = useRef(null);

    const fetchGames = useCallback(async() => {
        setShowLoading(true);
        const response = await ButikAPI.getGameGroupsForUser(userToken, appUser?.id_user);
        const data = await response.json();
        setShowLoading(false);
        if (data.status === "success") {
            setUserGames(data.gamegroups);
        } else {
            // Handle error or other statuses
            console.error("Failed to fetch games:", data);
        }
        setShowLoading(false);
        /* setShowLoading(true);
        return ButikAPI.getGameGroupsForUser(userToken, appUser?.id_user).then(r => r.json())
            .then(d => {
                setShowLoading(false);
                if (d.status === "success") {
                    setUserGames(d.gamegroups);//d.gamegroups.map((g) => addToUserGames(g));
                }
                else {

                }
            }); */
    },  [userToken, appUser?.id_user])

    useEffect(() => {
        const fetchData = async () => {
            let data;
            if (state !== undefined && state !== null) {
                data = state.data;
            }
            if (userGames?.length === 0) {
                await fetchGames();
            }

            if(data !== -1 && data !== undefined && data !== null && userGames?.length > 0){
                const group = userGames.find(exp => exp.id_group === data.id_game_group)?.group
                setSelected([userGames.find(exp => exp.id_group === data.id_game_group)?.rowKey.toString()]);
                setSelectedGroup(group);
                setShowLoading(true);
                const game = group.find(exp => exp.id_game === data.id_game);
                setSelectedGame([game.rowKey.toString()]);
                //setSelectedGame(Array.from(e.currentKey));
                //console.log("id is:" + e.target.value);
                setCurrentId(data.id_game);
                setCurrentGame(game);
                fetchHistory(data.id_game);

            }
        };

        fetchData();
    }, [userGames, fetchGames]);


    const handleSelectionChange = (e) => {
        setShowLoading(true);
        const selectedKey = e.currentKey;
        setSelectedGame([selectedKey]);
        //setSelectedGame(Array.from(e.currentKey));
        //console.log("id is:" + e.target.value);
        const id = selectedGroup.find(exp => exp.rowKey === parseInt(e.currentKey)).id_game;
        setCurrentId(id);
        setCurrentGame(selectedGroup.find(exp => exp.rowKey === parseInt(e.currentKey)))
        fetchHistory(id);
    }

    const handleSelectionGroupChange = (e) => {
        setSelected([e.currentKey]);
        setSelectedGroup(userGames.find(exp => exp.rowKey === parseInt(e.currentKey)).group);
        setGameHistory([]);
        setSelectedGame();
    }

    const fetchHistory = (id) => {
        return ButikAPI.getHistoryForUser(userToken, appUser.id_user, id).then(r => r.json())
            .then(d => {
                setShowLoading(false);
                if (d.status === "success") {
                    setGameHistory(d.history);
                }
                else {
                    console.log(d);
                }
            })
    }

    const goToDetails = (id) => {
        let data;
        if (id === 0) {
            data = [{
                id_status: 0,
                code_gameplay: generateCode(),
                id_user: appUser?.id_user,
                share_score: true,
                id_game: currentId
            }];
        }
        else {
            data = gameHistory.filter(g => g.id_gameplay === id);
            console.log(gameHistory.filter(g => g.id_gameplay === id));
        }

        navigate("/hoststartgame", { state: { data: data[0] } });
    }

    const generateCode = () => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 6) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const downloadPDF = async() =>{
        try {
            const url = currentGame.pdf_file;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                  'Accept': 'application/pdf',
                },
              });

              if (!response.ok) {
                throw new Error('Network response was not ok');
              }

              const blob = await response.blob();
              const urlBlob = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = urlBlob;
              link.setAttribute('download', currentGame.name + '.pdf'); // Suggest a filename

              // Append the link to the body
              document.body.appendChild(link);

              // Programmatically click the link to trigger the download
              link.click();

              // Clean up and remove the link
              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(urlBlob);
          } catch (error) {
            console.error('Error downloading the PDF', error);
          }
    }

    return (
        <div className="absolute inset-0 z-50" style={pic}>
            <HeaderComponent header="Starta lek" goBackScreen="/playgame" />

            <div className="fixed inset-2.5 top-16 bottom-2">
                {
                    userGames?.length > 0 ?

                    <div style={{ color: "504459" }} className="p-5 rounded-2xl shadow-lg bg-white bg-opacity-40 text-xl font-semibold h-full">
                        <div style={{height: "100%"}} className="flex flex-col">
                            <div className="grid grid-cols-1 gap-5 pb-5">
                                <Select
                                    label="Välj en grupp"
                                    selectedKeys={selected}
                                    onSelectionChange={handleSelectionGroupChange}>
                                    {userGames?.map((group) => (
                                        <SelectItem key={group.rowKey} value={group.id_group}>
                                            {group.name}
                                        </SelectItem>
                                    ))}
                                </Select>

                                <Select
                                    label="Välj en lek"
                                    selectedKeys={selectedGame}
                                    onSelectionChange={handleSelectionChange}>
                                    {selectedGroup?.map((game) => (
                                        <SelectItem key={game.rowKey.toString()}>
                                            {game.name}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                            <div className="overflow-auto grid grid-cols-1 gap-5 pb-12">
                                {gameHistory?.map((history) =>
                                    <div key={history.id_gameplay}
                                        className="flex justify-between border shadow p-2 bg-white rounded-xl"
                                        onClick={() => goToDetails(history.id_gameplay)}>
                                        <div className="flex  items-center">
                                            <span className="colored-circle shadow"
                                                style={
                                                    {
                                                        0: {backgroundColor : "#11DF06"},
                                                        1: {backgroundColor : "#FFF600"},
                                                        2: {backgroundColor : "#FF3800"}
                                                    }[history?.id_status]
                                                }/>
                                            <p>Kod: {history.code_gameplay} </p>
                                        </div>

                                        <div>
                                            <p>Players: {history.players_count}</p>
                                            <p>Teams: {history.teams_count}</p>
                                        </div>
                                    </div>
                                )}
                            {/*  {selectedGame !== null ?
                                    <Button
                                        className="bg-white"
                                        variant="shadow"
                                        size="lg"
                                        onPress={() => goToDetails(0)}>
                                        Starta nytt
                                    </Button>
                                    : ""} */}
                            </div>
                            {selectedGame !== null && selectedGame !== undefined?
                                <div className="absolute bottom-0 left-0 right-0 pt-5">
                                    <div className="grid grid-cols-2 p-2 gap-5">
                                        <Button
                                            className="bg-white "
                                            variant="shadow"
                                            size="lg"
                                            onPress={() => goToDetails(0)}>
                                            Starta ny
                                        </Button>
                                        <Button
                                            className="bg-white "
                                            variant="shadow"
                                            size="lg"
                                            onPress={downloadPDF}>
                                            Ladda ner pdf
                                        </Button>
                                    </div>
                                </div>
                                : ""}
                        </div>

                    </div> :

                    <span style={{color: "#504459"}} className="pt-10 text-xl font-semibold">
                        Du har inga lekar.
                    </span>
                }
            </div>


        </div>
    )
}

export default HostScreen;
