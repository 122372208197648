import React, { useState } from "react";
import { Input, Button } from "@nextui-org/react";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

function SearchComponent(props) {
    const [wordToSearch, setWordToSearch] = useState("");

    const handleAction = (e) => {
        if(e.key === "Enter"){
            props.search(wordToSearch);
        }
    }

    const handleChange = (newValue) => {
        setWordToSearch(newValue);
        props.search(newValue);
    }

    const openFilter = () => {
        if(props.openFilter !== null && props.openFilter !== undefined)
            props.openFilter();
    }

    return(
        <>
            <Input
                isClearable
                radius="lg"
                onKeyDown={handleAction}
                value={wordToSearch}
                onValueChange={handleChange}
                classNames={{
                input: [
                    "bg-transparent",
                    "text-black/90 dark:text-white/90",
                    "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                ],
                innerWrapper: "bg-transparent",
                inputWrapper: [
                    "shadow-xl",
                    "bg-default-200/50",
                    "dark:bg-default/60",
                    "backdrop-blur-xl",
                    "backdrop-saturate-200",
                    "hover:bg-default-200/70",
                    "dark:hover:bg-default/70",
                    "group-data-[focused=true]:bg-default-200/50",
                    "dark:group-data-[focused=true]:bg-default/60",
                    "!cursor-text",
                ],
                }}
                placeholder="Vad letar du efter..."
                startContent={
            <SearchOutlinedIcon  className="text-black/50"/>}/>
        </>
    )
}

export default SearchComponent;
