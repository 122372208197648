import React from "react";

function Terms(){
    return(
        <div className="grid grid-cols-1 gap-2">
<p>Vad kul att du vill använda Pimparty! Innan du börjar använda Pimparty så rekommenderar vi att du läser igenom villkoren nedan. Det som står i denna text är avtalet mellan dig och oss.</p>

<div className="pt-5 pb-2">
<span className="text-2xl font-bold">Användarvillkor</span>
</div>
<p>Det sker kontinuerlig uppdatering av villkoren, (se punkt 11), men den senaste versionen finns alltid tillgänglig i appen. Sådana ändringar blir gällande så fort du tagit del av dem, eller senast 30 dagar efter att de publicerats.</p>

<p className="text-xl font-normal">1 Allmänt och definitioner</p>

<p>1.1 För att undvika upprepningar och förenkla läsandet så följer nedan en lista på ord som har särskild betydelse i villkoren.</p>

<p>”Användare”: är den som använder Pimparty med eller utan användarkonto. “Användarkonto”: användarkonto som du registrerar i Pimparty för att kunna använda det. För det fall inget användarkonto finns registrerat, ska ”Användarkonto” istället stå för, avse ditt användande av Pimparty.</p>
<p>”Data”: Är all data som överförs av dig till I.M FESTLIGATIPS via Pimparty när du använder appen.</p>
<p>”Hemsidan”: www.pimparty.se</p>
<p>”Pimparty”: Appen Pimparty som tillhandahålls av I.M FESTLIGATIPS.</p>
<p>“Lekar”: Är de material (lekar, aktiviteter, tävlingar) som finns att tillgå i appen så som frågeslingor, bingo, musikquiz osv.</p>
<p>”Villkoren”: Dessa användarvillkor vilka gäller mellan dig och oss. </p>

<p className="text-xl font-normal">2 Om Pimparty</p>

<p>2.1 Pimparty är en app som tillhandahålls av I.M FESTLIGATIPS och som utgör ett stöd för personer som ska delta i aktiviteter för olika tillställningar men även ett stöd för den som ordnar tillställningar. Genom Pimparty kan du enkelt hitta och köpa lekar och aktiviteter som passar till olika tillställningar, fester och kalas. Det finns även lekar som är gratis som du kan använda om du skapar ett konto i appen. </p>

<p>2.2 Användare kan antingen agera som en värd i appen (den som anordnar en tillställning) eller som deltagare - den person som är gäst på en tillställning. </p>

<p>2.3 Mer information om Pimparty finns att tillgå i Pimparty appen och på Hemsidan.</p>

<p className="text-xl font-normal">3 Användning av Pimparty</p>

<p>3.1 Användning av Pimparty är gratis, men med valmöjlighet för Användaren att betala för tillgång av specifika lekar.</p>

<p>3.2 För att kunna använda Pimparty krävs att du tillhandahåller viss information i enlighet med instruktioner som ges i Pimparty. Se Integritetspolicy för mer information.</p>

<p className="text-xl font-normal">4 Missbruk</p>

<p>4.1 I.M FESTLIGATIPS har alltid rätt att avsluta ditt Användarkonto. Om du exempelvis på något sätt missbrukar Pimparty. Missbruk kan exempelvis bestå av brott mot Villkoren. Du är skyldig att ersätta I.M FESTLIGATIPS för eventuella kostnader som missbruket resulterar i.</p>

<p className="text-xl font-normal">5 Ändringar, uppdateringar och begränsad tillgång till Pimparty</p>

<p>5.1 I.M FESTLIGATIPS förbehåller sig rätten att när som helst ändra det visuella uttrycket, och även i övrigt genomföra uppdateringar i Pimparty. I.M FESTLIGATIPS förbehåller sig även rätten att ändra, avbryta och/eller avsluta funktioner i Pimparty inklusive – men inte begränsat till – funktionalitet och tjänster, oavsett om förvarning om detta sker till dig eller inte. All ny funktionalitet och/eller nya tjänster som lanseras i Pimparty omfattas av Villkoren.</p>

<p>5.2 I.M FESTLIGATIPS förbehåller sig även rätten att när som helst, med eller utan förvarning, vidta åtgärder som påverkar din tillgång till Pimparty om detta är nödvändigt med hänsyn till bland annat tekniska, underhålls- eller säkerhetsmässiga skäl. I.M FESTLIGATIPS ska vidta sådana åtgärder skyndsamt och på ett sätt som i minsta mån orsakar störningar för dig.</p>

<p>5.3 I.M FESTLIGATIPS utövande av ovanstående rättighet ger dig inte rätt att kräva någon ersättning.</p>

<p className="text-xl font-normal">6 Immateriella rättigheter</p>

<p>6.1 I.M FESTLIGATIPS äger samtliga rättigheter, inklusive immateriella rättigheter hänförliga till Pimparty, inkluderat, men ej begränsat till, upphovsrätt, patent, varumärken, firma, mönster, programvara, källkod, databaser, affärsplaner, design och know-how, oavsett om rättigheterna är registrerade eller inte. All dokumentation, inklusive layout, design och varumärke, redaktionell text och film, manualer, användarguider, eller andra skriftliga, elektroniska eller icke-elektroniska, beskrivningar av hur Pimparty är upprättad och används, anses vara en del av Pimparty och är därmed föremål för samma restriktioner. Inget i Villkoren ska tolkas så att någon av dessa rättigheter helt eller delvis överlåtas till dig.</p>

<p>6.2 Du är fortsatt ägare av all Data som ägs av dig och som överförs till I.M FESTLIGATIPS genom Pimparty. I.M FESTLIGATIPS gör inte anspråk på immateriella rättigheter, eller äganderätt av något slag, till sådan Data.</p>

<p className="text-xl font-normal">7 Support, kommunikation och kontaktuppgifter</p>

<p>7.1 Du accepterar att all kommunikation mellan I.M FESTLIGATIPS och dig relaterat till Pimparty får ske elektroniskt, exempelvis genom e-post, via e-postadressen info@festligatips.com, eller via Pimparty. Exempel på meddelanden är information om driftstörningar, nya versioner, övrig information om Pimparty samt support.</p>

<p className="text-xl font-normal">8 Användarkonto - Betalning, priser och ångerrätt</p>

<p>8.1 För att få tillgång till möjligheten att köpa lekar i Pimparty behöver du skapa ett konto om du inte redan har det samt genomföra ett köp samt godkänna dessa Villkor. Anledningen till varför du behöver ett konto är för att du ska kunna ha tillgång till innehållet om du byter telefon, samt av tekniska skäl.</p>

<p>8.2 Du ansvarar för att tillhandahålla korrekt och uppdaterad information vid registrering. Vi skyddar din personliga integritet enligt vår integritetspolicy.</p>

<p>8.3 Ett bindande avtal om köp ingås genom din betalning i Pimparty. När du gör köpet ska du vara medveten om att det medför en betalningsskyldighet för den beställda tjänsten. </p>

<p>8.4 Ingen ångerrätt finns för köpen i appen då produkten är digital och kan användas direkt efter köp. </p>

<p>8.5 Vi förbehåller oss rätten att kunna ändra priserna i appen när vi vill. I det pris som visas i samband med ditt köp ingår moms.</p>

<p className="text-xl font-normal">9 Ansvarsbegränsning</p>

<p>9.1 Pimparty levereras i befintligt skick och I.M FESTLIGATIPS ger inte någon garanti, vare sig uttrycklig eller underförstådd, med undantag för vad som uttryckligen framgår av Villkoren, inkluderat men inte uteslutande, garantier rörande lämplighet för visst ändamål för dig.</p>

<p>9.2 Du är medveten om att tillgång till internet krävs för att tillgå och använda vissa delar av Pimparty och att sådan tillgång varken installeras, underhålls eller etableras av I.M FESTLIGATIPS, samt således inte kan garantera någon säker anslutning till Pimparty.</p>

<p>9.3 I.M FESTLIGATIPS är under inga omständigheter skyldigt att tillhandahålla Pimparty för dig.</p>

<p className="text-xl font-normal">10 Force majeure</p>

<p>10.1 Med undantag för de fall I.M FESTLIGATIPS eller du har agerat grovt vårdslöst är I.M FESTLIGATIPS respektive du alltid befriad från ansvar för brott mot Villkoren som orsakats av händelser utanför I.M FESTLIGATIPS:s respektive din kontroll, såsom åsknedslag, eldsvåda, strömavbrott, strejk, krig, mobilisering eller militärinkallelse av större omfattning, rekvisition, beslag, myndighetsbestämmelser, myndighetsåtgärd, nytillkommen eller ändrad lagstiftning, konflikt på arbetsmarknaden och därmed jämställda omständigheter.</p>

<p className="text-xl font-normal">11 Ändring av villkoren</p>

<p>11.1 I.M FESTLIGAIPS förbehåller sig rätten att när som helst ändra eller göra tillägg till Villkoren. I.M FESTLIGATIPS avgör när sådana eventuella ändringar och/eller tillägg ska träda i kraft. Uppgifter om villkorsändringar ska alltid på ett tydligt sätt framgå för dig genom Pimparty.</p>
<p>11.2 Du har alltid rätt att avsluta ditt Användarkonto i det fall om inte samtycker till sådana villkorsändringar som avses i punkten ovan. Om du fortsätter att använda Pimparty efter att ändringar eller tillägg trätt i kraft, anses du bunden av de nya eller ändrade villkoren.</p>

<p className="text-xl font-normal">12 Tvist utanför domstol</p>

<p>12.1 Om du tycker att en tjänst du köpt hos oss är felaktig men vi inte håller med dig så har vi en tvist. För att få denna tvist prövad kan du vända dig till Allmänna reklamationsnämnden, www.arn.se. Vi medverkar i tvistlösningsförfarandet och följer ARN:s rekommendationer.</p>

<p className="text-xl font-normal">13 Tillämplig lag och tvistelösning</p>

<p>13.1 På Villkoren ska svensk rätt tillämpas såvida tvingande lag inte föreskriver annorlunda.</p>

<p>13.2 Tvister som uppstår i anledning av Villkoren eller i övrigt i anledning av ditt användande av Pimparty, ska avgöras i allmän domstol.</p>

<div className="pt-5 pb-2">
<span className="text-2xl font-bold">Sekretesspolicy</span>
</div>

<p>Appen Pimparty drivs av I.M FESTLIGATIPS. I.M FESTLIGATIPS är ansvarig för behandlingen av personuppgifter i appen Pimparty och vi är angelägna om att skydda våra användares personliga integritet och eftersträvar därför att upprätthålla högsta möjliga standard beträffande skyddet av personuppgifter. Därför åtar vi oss att följa denna personuppgiftspolicy. Du hittar här information om hur vi hanterar dina personuppgifter som användare. Läs därför igenom detta innan du börjar använda appen. Personuppgiftspolicyn är ett komplement till våra användarvillkor som du hittar ovan. För det fall det skulle föreligga motstridigheter mellan Personuppgiftspolicyn och Villkoren, ska Personuppgiftspolicyn äga företräde.</p>
<p className="text-xl font-normal">
    1 Om personuppgiftslagstiftningen
</p>
<p>
    1.1 För de uppgifter som användaren lämnar (enligt definitionen nedan) och som betraktas som personuppgifter ska vi följa tillämplig personuppgiftslagstiftning, bland annat den så kallade dataskyddsförordningen 2016/679 (GDPR) och lag 2018:218 med kompletterande bestämmelser till EU:s dataskyddsförordning. Alla personuppgifter som användaren lämnar till oss inom ramen för Pimparty kommer att behandlas av oss i egenskap av personuppgiftsansvarig.
</p>
<p>
    1.2 Om du lägger upp andras personuppgifter i Pimparty, till exempel namn, bör du se till att du har sådana personers tillåtelse att lägga upp de uppgifterna i appen.
</p>
<p>
    1.3 I den utsträckning dina personuppgifter lagts in i Pimparty av någon annan så omfattas även du av denna Personuppgiftspolicy och vi är personuppgiftsansvariga även för denna behandling.
</p>
<p className="text-xl font-normal">
    2 Personuppgifter, ändamål och laglig grund
    </p>
<p>
    2.1 Kontoinformation - För att registrera ett konto måste du tillhandahålla följande information:
    </p>
<p>
    1.	E-postadress
    </p>
<p>
    2.	Lösenord
    </p>
<p>
    3.	Namn som uppges när man ska leka en lek
    </p>
<p>
    Ändamålet med behandlingen av uppgifterna är att tillhandahålla en säker inloggning till ditt konto och för att vi ska kunna kontakta dig med viktig information såsom uppdaterade villkor eller lösenordsåterställning. Den lagliga grunden för behandlingen är avtalet med dig som användare.
    </p>
<p>
    2.2 Betalningsinformation - Betalning av lekarna sker via Klarna eller Stripe (kort). Vid köp behandlas information såsom: vad som köptes, betalningsmetod, tid för köp och pris. I samband med en betalning så överför vi betalningsuppgifterna till vald leverantör för betaltjänsten som i sin tur är personuppgiftsansvarig för sin behandling av dina personuppgifter.
    </p>
<p>
    Ändamålet med behandlingen är att kunna genomföra önskade köp.
    </p>
<p>
    Den lagliga grunden för behandlingen är avtalet med dig som användare.
    </p>
<p className="text-xl font-normal">
    3 Hantera din personliga information
    </p>
<p>
    Hur länge vi lagrar personuppgifter beror på vilken laglig grund vi har för behandlingen. Personuppgifterna kommer att lagras tills det inte längre finns någon laglig grund. Samma uppgift kan behandlas för olika grunder och kommer då att raderas när samtliga grunder är inaktuella.
    </p>
<p>
    3.1 Personuppgifter som vi använder för att de är nödvändiga för att fullgöra avtalet med dig behandlar vi till dess att du avslutar ditt konto och begär radering. Om du inte särskilt begär radering kommer kontoinformation att sparas i upp till 24 månader, för att möjliggöra ett återställande av ditt konto.
    </p>
<p>
    3.2 Personuppgifter som vi använder med stöd av ditt samtycke behandlas till dess att du återkallar ditt samtycke. Om du avslutar ditt konto kommer uppgifterna att sparas på samma sätt som kontoinformation.
    </p>
<p>
    3.3 Uppgifter om användning - Den enda informationen kring uppgifter om användning som vi samlar in är information om vilka lekar du som användare köper, samt de lekar som man “hjärtar/gillar”.
    </p>
<p className="text-xl font-normal">
    4 Dina rättigheter
    </p>
<p>
    När Pimparty behandlar dina personuppgifter har du vissa rättigheter som användare. Du har rätt att få information om vilka uppgifter vi behandlar om dig, att se till att uppgifterna är korrekta och att klaga om du tycker att vi gör något som är fel.
    </p>
<p>
    4.1 Mer specifikt, har du följande rättigheter: - Rätt att få information om de personuppgifter som vi behandlar om dig; - Rätt att få eventuella felaktiga personuppgifter rättade eller blockerade under en tid då vi kan säkerställa att de är felaktiga; - Rätt att få dina uppgifter raderad efter att ditt konto har avslutats; - Rätt att begära att behandlingen av dina uppgifter begränsas under vissa förutsättningar om så framgår av artikel 18 i den Allmänna dataskyddsförordningen (GDPR), t.ex. om du anser att uppgifterna inte är korrekta eller att det saknas en rättslig grund men du inte vill att uppgifterna raderas; - Rätt till ”dataportabilitet”, det vill säga att få ut de uppgifter som du har tillhandahållit oss i ett ”strukturerat, allmänt använt och maskinläsbart format ”; och - Rätt att klaga på hur vi behandlar dina personuppgifter.
    </p>
<p className="text-xl font-normal">
    5 Säkerhet
    </p>
<p>
    5.1 Vi värnar om din personliga integritet och ditt intresse av att dina personuppgifter behandlas med största försiktighet. Vi tillämpar därför höga säkerhetsstandarder och gör vårt yttersta för att säkerställa hög datasäkerhet.
    </p>
<p>
    5.2 Vi kommer att vidta alla åtgärder som rimligen kan krävas för att säkerställa att dina personuppgifter behandlas säkert och i enlighet med Personuppgiftspolicyn. När det kommer till internetsäkerhet är dock inget system helt säkert. Överföring sker således på egen risk. Om en säkerhetsöverträdelse inträffar kommer Pimparty givetvis att agera så fort som möjligt för att åtgärda problemet.
    </p>
<p>
    5.3 Vi kommer aldrig sälja eller dela med oss av några personuppgifter från Pimparty till utomstående parter, förutom om så krävs av oss enligt lag eller något annat som står i denna sektion.
    </p>
<p>
    5.4 Pimparty använder sig av externa företag som agerar personuppgiftsbiträden för behandling av dina personuppgifter, exempelvis vid lagring av data på servrar. Vi använder endast aktörer som kan säkerställa att dina personuppgifter behandlas på ett korrekt sätt. Sådana personuppgiftsbiträden får aldrig behandla dina personuppgifter på annat sätt än på uppdrag av oss och i enlighet med Personuppgiftspolicyn.
    </p>
<p>
    5.5 Vi kommer inte använda din e-postadress för oönskade e-postutskick eller dela med oss av din e-postadress till tredje part utan ditt samtycke. Eventuella e-postmeddelanden som skickas från Pimparty kommer att ske helt i enlighet med Personuppgiftspolicyn och Villkoren.
    </p>
<p className="text-xl font-normal">
    6 Länkar till andra webbplatser
    </p>
<p>
    6.1 Pimparty kan komma att innehålla länkar eller referenser till andra webbplatser där Personuppgiftspolicyn inte gäller. Om du klickar på dessa länkar och därmed lämnar Pimparty, är vi inte ansvariga för hur dessa webbplatser hanterar dina personuppgifter.
    </p>
<p className="text-xl font-normal">
    7 Ändringar
    </p>
<p>
    7.1 Vi kan ändra Personuppgiftspolicyn. Alla ändringar träder i kraft per automatik 30 dagar efter att de publicerats i Pimparty. Om vi gör ändringar i Personuppgiftspolicyn som bedöms vara nödvändiga för att uppfylla skyldigheter enligt lag eller myndighets utlåtande eller beslut, ska den nya Personuppgiftspolicyn gälla omedelbart efter att den publicerats i Pimparty.
</p>




        </div>
    )
}

export default Terms;
