import { Button, Checkbox, CheckboxGroup } from "@nextui-org/react";
import React, { useState, useEffect, useCallback } from "react";
import { ButikAPI } from "../../apis/ButikAPI.js";

function Filter(props){
    const [selectedCategories, setSelectedCategories] = useState(props.selectedCategories);
    const [selectedTags, setSelectedTags] = useState(props.selectedTags);
    const [sort, setSort] = useState(props.sort);
    const [categories, setCategories] = useState();
    const [subCategories, setSubCategories] = useState();

    useEffect(() => {
        setCategories(props.categories);
        setSubCategories(props.subCategories);
    }, [categories])

    const changeSort = (e) => {
        setSort(e.target.value);
    }

    const onFilter = () => {
        if(props.filer !== null && props.filter !== undefined)
            props.filter(selectedCategories, selectedTags, sort);
    }

    const onCancel = () => {
        if(props.cancelFilter !== null && props.cancelFilter !== undefined)
            props.cancelFilter();
    }

    return(
        <div className="h-screen overflow-y-auto pb-20">
            <div className="grid grid-cols-1 justify-center gap-5 ">
                <div>
                    <CheckboxGroup label="Tema" value={selectedCategories} onValueChange={setSelectedCategories}>
                        {categories?.map((cat) => <Checkbox key={cat.id_category} value={cat.id_category}>{cat.name}</Checkbox>)}
                    </CheckboxGroup>
                </div>
                <div>
                    <CheckboxGroup label="Tags" value={selectedTags} onValueChange={setSelectedTags}>
                        {subCategories?.map((cat) => <Checkbox key={cat.id_category} value={cat.id_category}>{cat.name}</Checkbox>)}
                    </CheckboxGroup>
                </div>
                <div className="grid grid-cols-1 gap-3">
                    <span>Sortering</span>
                    <Checkbox
                        value="priceDesc"
                        onChange={changeSort}
                        isSelected={sort === "priceDesc" ? true : false}>
                            Högsta pris
                    </Checkbox>
                    <Checkbox
                        value="priceAsc"
                        onChange={changeSort}
                        isSelected={sort === "priceAsc" ? true : false}>
                            Lägsta pris
                    </Checkbox>
                    <Checkbox
                        value="newest"
                        onChange={changeSort}
                        isSelected={sort === "newest" ? true : false}>
                            Nyast
                    </Checkbox>
                </div>
                <div className="grid grid-cols-1 gap-3">
                    <Button
                        style={{backgroundColor: "#504459"}}
                        className="h-10 text-xl text-white"
                        variant="flat"
                        color="danger"
                        aria-label="ShowAll"
                        onPress={onFilter}>
                                Visa resultat
                        </Button>
                    <Button style={{color: "#504459"}}
                        className="font-normal italic h-8 text-lg"
                        variant="light"
                        size="lg"
                        aria-label="goshop"
                        onPress={onCancel}>
                            Rensa filter
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Filter;
