import React, { useEffect } from "react";
import { Card, CardBody } from "@nextui-org/react";

function Quiz1x2(props){
    const boxStyle = "flex justify-center text-center items-center border border-gray-400";
    const lineStyle = "grid grid-cols-4 h-4/5";

    useEffect(() => {
        console.log(props.answers);

        props.answers?.map((item) => {
            let i = "0";
            let game = props.games.find(exp => exp.id_bb_data === item.id_bb_data);

            switch(item.answer){
                case game.tips_1: i = "0";
                    break;
                case game.tips_x: i = "1";
                    break;
                case game.tips_2: i = "2";
                    break;
                default: i = "0";
            }

            document.getElementById(item.id_bb_data + i).className += " bg-yellow-200";
        })

    }, [])

    const handleClick = (e, id) => {
        let index = e.target.id;
        const item = props.games.find(exp => exp.id_bb_data === id);
        const answerIndex = index.substring(index.length-1);
        index = index.substring(0,index.length-1);
        let answer = "";
        switch(answerIndex){
            case "0": answer = item.tips_1;
                break;
            case "1": answer = item.tips_x;
                break;
            case "2": answer = item.tips_2;
                break;
            default: answer = "";
        }

        // set the other buttons to boxStyle
        for(let i = 0; i < 3; i++){
            document.getElementById(index+i).className = boxStyle;
        }
        e.target.className += " bg-yellow-200";

        props.changeAnswers({id_bb_data: id, answer: answer});
    }

    return(
            <Card className=" bg-white bg-opacity-90" shadow="lg">
                <CardBody>
                    <div className= "grid grid-cols-4 h-4/5 text-center h-8">
                        <div/>
                        <span style={{color: "#504459"}} className="text-xl font-semibold">1</span>
                        <span style={{color: "#504459"}} className="text-xl font-semibold">x</span>
                        <span style={{color: "#504459"}} className="text-xl font-semibold">2</span>
                    </div>
                    {props.games?.map((input, i)=>
                        <div key={i} className={lineStyle}>
                            <button
                                style={{color: "#504459"}}
                                className="text-xl font-semibold border border-gray-400" >
                                {i+1}
                            </button>

                            <button id={input.id_bb_data + "0"} className={boxStyle} onClick={(e) => handleClick(e, input.id_bb_data)}>

                            </button>

                            <button id={input.id_bb_data  + "1"} className={boxStyle} onClick={(e) => handleClick(e, input.id_bb_data)}>

                            </button>

                            <button id={input.id_bb_data  + "2"} className={boxStyle} onClick={(e) => handleClick(e, input.id_bb_data)}>

                            </button>
                        </div>
                    )}
                </CardBody>
            </Card>
    )
}

export default Quiz1x2;
