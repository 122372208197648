import React, {useEffect} from 'react';
import { isDev } from '../../util/isDev';

function KlarnaPage(props){

    const KlarnaURL = //isDev ? "http://localhost:8080/admin/Klarna/KlarnaPage.html" :
     "https://www.pimparty.se/admin/Klarna/KlarnaPage.html";

     useEffect(() => {
        const iframe = document.getElementById('klarna-checkout-iframe');

        // Delay setting the src and posting the message
        const timeoutId = setTimeout(() => {
            iframe.src = KlarnaURL;
            iframe.onload = () => {
                iframe.contentWindow.postMessage(props.snippet, '*');
            };
        }, 1000); // Delay of 1000 milliseconds (1 second)

        // Cleanup timeout if the component unmounts before the timeout completes
        return () => clearTimeout(timeoutId);
    }, [props.snippet]);


    const testLoad = (e) => {
        try {
            const iframe = document.getElementById('klarna-checkout-iframe');
            const iframeWindow = iframe.contentWindow || iframe.contentDocument;
            const iframeLocation = iframeWindow.location.href;

            console.log('Iframe loaded', iframeLocation);

            if (iframeLocation.includes('KlarnaPageConfirmation')) {
                console.log('Checkout completed, redirected to confirmation page');
                props.buyItems();
            }

        } catch (error) {
            console.error('Error accessing iframe content', error);
        }
    }

    return(
        <div>
            <iframe
                id="klarna-checkout-iframe"
                name="klarna-checkout-iframe"
                title="Klarna Checkout"
                className="w-full"
                style={{height: '586px'}}
                onLoad={(e) => testLoad(e)}>

                </iframe>
        </div>
    )
}

export default KlarnaPage;
