import React, {useContext, useState, useEffect, useCallback, useRef} from "react";
import { Accordion, AccordionItem, Button, Card, CardBody, Input, Skeleton } from "@nextui-org/react";
import { ShopContext } from "../../context/ShopContextProvider";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { ButikAPI } from "../../apis/ButikAPI";
import KlarnaPage from "../KlarnaCheckout/KlarnaPage";
import { KlarnaOrder, KlarnaItem } from "../KlarnaCheckout/KlarnaOrder";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import StripePage from "../StripeCheckout/StripePage";
import Login from "../Login/Login";
import { domAnimation } from "framer-motion";

const stripePromise = await loadStripe('pk_test_51OWFJKHXRPyZCD2UUvhbnzw2Rpdf8JmHFtWOi3XnR88ticGXZcANp0Kdgsy5JnekN88xVchqmDLuWtaSU22hix2U00vSzPtCvF');


function PurchaseGame(props){
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const {klarnaOrderId, setKlarnaOrderId, cartItems, clearCart, userToken, appUser, isLoggedIn, setShowLoading } = useContext(ShopContext);
    const [klarnaSnippet, setKlarnaSnippet] = useState();
    const [discount, setDiscount] = useState('');
    const [discountCode, setDiscountCode] = useState('');
    const [isDiscount, setIsDiscount] = useState(false);
    const [partialSum, setPartialSum] = useState(0);
    const [discountSum, setDiscountSum] = useState(0);
    const [totalSum, setTotalSum] = useState(0);

    useEffect(() => {
        setPartialSum(cartItems?.reduce((total, item) => total = total + item.price, 0));
        setTotalSum(cartItems?.reduce((total, item) => total = total + item.price, 0));
    }, [cartItems]);

    const goToShop = () => {
      if(props.goToShop !== null && props.goToShop !== undefined)
        props.goToShop();
    }

    //After payment is confirmed
    const buyProducts = () => {
        setShowLoading(true);
        console.log(cartItems);
        var procesedItems = cartItems?.map((item) => ({id: item.id, bought: true, favorite: false}));

        ButikAPI.updateSeveralProducts(userToken, procesedItems)
        .then(r => r.json())
        .then(d => {
            setShowLoading(false);
            if (d.status === "success") {
                //d.products.forEach(g => addToUserGames(g))
                console.log(d);
                clearCart();
            }
            else {
                console.log(d);
            }
        });
      //onOpenChange();
    }

    const paymentMethodChanged = (e) =>{
      console.log(e.currentKey);
      var products = [];

      // Card payment
      if(e.currentKey === "1"){

      }

      // Klarna Payment
      if(e.currentKey === "2" && e.size > 0){
        //console.log(cartItems);
        var products = [];
        var productIds = [];

        // Prepare the products
        cartItems.map((item) => {
          var prod = KlarnaItem(item.id, item.name, item.price);
          products.push(prod);
          productIds.push(item.id)
        });

        // Prepare the order
        var order = KlarnaOrder(totalSum, products);
        console.log(JSON.stringify(order));

        // Send the order to Klarna and retrieve the snippet
        // Send to ButikAPI
        if(klarnaOrderId !== '')
            getKlarnOrder();
        else
            createKlarnaOrder(order, productIds);

        // wait for the order id?

      }
    }

    const fetchKlarnaStatus = () => {

        ButikAPI.getKlarnaOrder(userToken, klarnaOrderId)
        .then(r => r.json())
          .then(d => {
              if (d.status === "success") {
                    //d.products.forEach(g => addToUserGames(g))
                    var order = d.order;
                    if(order.status === 'checkout_complete'){
                        buyProducts();
                    }
                }
              else {
                  console.log(d);
              }
          })
      }

    const getKlarnOrder = () => {

        ButikAPI.getKlarnaOrder(userToken, klarnaOrderId)
        .then(r => r.json())
          .then(d => {
              if (d.status === "success") {
                  //d.products.forEach(g => addToUserGames(g))
                  var order = d.order;
                  //console.log(order.html_snippet);
                  setKlarnaSnippet(JSON.stringify(order.html_snippet));
                  //startInterval();

                }
              else {
                  console.log(d);
              }
          })
      }

    const createKlarnaOrder = (order, productIds) => {

      ButikAPI.createKlarnaOrder(userToken, JSON.stringify(order), appUser.id_user,  JSON.stringify(productIds))
      .then(r => r.json())
        .then(d => {
            if (d.status === "success") {
                //d.products.forEach(g => addToUserGames(g))
                var order = d.order;
                setKlarnaOrderId(order.order_id);
                //console.log(order.html_snippet);
                setKlarnaSnippet(JSON.stringify(order.html_snippet));
                //startInterval();
            }
            else {
                console.log(d);
            }
        })
    }

    const handleBuy = () =>{
        if(isLoggedIn){
            if(cartItems?.reduce((total, item) => total = total + item.price, 0) === 0)
                buyProducts();
            else
                onOpen();
        }
        else
            props.openLogin();
    }

    const calculateNewTotal = (item) => {
        setIsDiscount(true);
        const cost = partialSum === 0 ? cartItems?.reduce((total, item) => total = total + item.price, 0) : partialSum;
        setDiscountSum(item[0].value/100*cost);
        setTotalSum(cost - item[0].value/100*cost);
    }

    const addDiscount = useCallback(() => {
        setShowLoading(true);

        const code = discount === "" ? document.getElementById("discountInput").value : discountCode;

        return ButikAPI.checkDiscount(code)
            .then(r => r.json())
            .then(d => {
                setShowLoading(false);
                if (d.status === "success" && d.discount?.length > 0) {
                    setDiscount(d.discount);
                    calculateNewTotal(d.discount);
                }
                else {
                    alert('Ingen rabatt finns med den koden.');
                }
        })
    }, [])

    const deleteDiscount = () => {
        setDiscountSum(0);
        setIsDiscount(false);
        setTotalSum(partialSum);
    }

    return(
        <>
        <div className="fixed bottom-10 w-full left-0">
            <Card
                isBlurred
                className="border-none bg-background/60 dark:bg-default-100/50"
                shadow="sm">
                <CardBody className="p-3 pb-12">
                    <div style={{color: "#504459"}} className="grid grid-cols-1 gap-1 text-xl font-semibold">


                        <Button style={{backgroundColor: "#504459"}}
                            className="h-14 text-xl text-white"
                            variant="flat"
                            color="danger"
                            aria-label="Buy"
                            onPress={handleBuy}>
                            {/* onPress={buyProducts}> */}
                                <div className="p-2 flex justify-between w-full">
                                    <span >Kassa</span>
                                    <span>{partialSum} :-</span>
                                </div>
                                </Button>
                        <Button style={{color: "#504459"}}
                            className="font-normal italic h-8"
                            variant="light"
                            size="lg"
                            aria-label="goshop"
                            onPress={goToShop}>
                                    Köp vidare
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>


    <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="center" size="full">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Kassa</ModalHeader>
              <ModalBody className="overflow-y-auto">
                <div className="h-full overflow-y-auto">
                    <div style={{color: "#504459"}} className="grid grid-cols-1 md:grid-cols-12 gap-1 md:gap-4 text-xl font-semibold ">
                        <div className="pl-2 pr-2 flex justify-between text-lg">
                            <span >{isDiscount ? 'Delsumma' : 'Total'}</span>
                            <span>{partialSum} :-</span>
                        </div>

                        {isDiscount ?
                                <>
                                    <div className="pl-2 flex justify-between text-lg">
                                        <span >Rabattkod: {discountCode}</span>
                                        <Button className="h-8" color="danger" onPress={deleteDiscount}>
                                            Ta bort
                                        </Button>
                                    </div>
                                    <div className="pl-2 pr-2 flex justify-between text-lg">
                                        <span >Rabatt</span>
                                        <span>{discountSum} :-</span>
                                    </div>
                                    <div className="pl-2 pr-2 flex justify-between font-bold text-2xl border-t">
                                        <span >Total</span>
                                        <span>{totalSum} :-</span>
                                    </div>
                                    {totalSum === 0 ?
                                    <div className="pl-2 flex justify-between">
                                        <span > </span>
                                        <Button className="h-10" color="danger" onPress={buyProducts}>
                                            Köp
                                        </Button>
                                    </div> : ""}
                                </>
                                :
                                <div>
                                    <Input
                                        id="discountInput"
                                        label="Rabattkod"
                                        type="text"
                                        value={discountCode}
                                        onValueChange={setDiscountCode} />
                                    <div className="py-2 flex justify-between">
                                        <span > </span>
                                        <Button style={{backgroundColor: "#504459"}}
                                            className="h-8 text-white"
                                            variant="flat"
                                            color="danger"
                                            aria-label="Buy"
                                            onPress={addDiscount}>
                                                    Lägg till
                                        </Button>
                                    </div>
                                </div>
                            }
                    </div>
                    {totalSum > 0 ?
                    <Accordion variant="bordered" onSelectionChange={paymentMethodChanged}>
                        <AccordionItem key="1" aria-label="Kort" title="Kortbetalning">
                            <Elements stripe={stripePromise}>
                                <StripePage />
                            </Elements>
                        </AccordionItem>
                        <AccordionItem key="2" aria-label="Klarna" title="Klarna Checkout" >
                            {klarnaSnippet !== undefined && klarnaSnippet !== null ?
                            <KlarnaPage snippet={klarnaSnippet} buyItems={buyProducts}/>
                            : "" }
                        {/*  <iframe
                            id="klarna-checkout-iframe"
                            name="klarna-checkout-iframe"
                            src="http://localhost:8080/admin/Klarna/KlarnaPage.html"
                            title="Klarna Checkout"
                            className="w-full"
                            style={{height: '586px'}}></iframe> */}
                        </AccordionItem>
                    </Accordion> : ""}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onPress={onClose}>
                  Stäng
                </Button>
                {/* <Button color="primary" onPress={buyProducts}>
                  Köp
                </Button> */}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
        </>
    )
}

export default PurchaseGame;
