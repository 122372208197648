import React, { useContext } from 'react';
import { ShopContext } from "../../context/ShopContextProvider";
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { Badge } from '@nextui-org/react';

function CartIcon(){
    const {cartItems} = useContext(ShopContext);
    return(
        <React.Fragment>
{/*             {cartItems?.length > 0 ? <span className='badge badge-warning' id='lblCartCount'> {cartItems?.length} </span> : "" }
 */}            
            <Badge color="danger" content={cartItems?.length} shape="circle" isInvisible={cartItems?.length > 0 ? false : true}>
                <ShoppingBagOutlinedIcon fontSize="large"/>
            </Badge>
        </React.Fragment>
    )
}

export default CartIcon;