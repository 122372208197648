import './App.css';
import { Route, Routes } from 'react-router-dom';
import AllGames from './screens/AllGames';
import FavoriteGames from './screens/FavoriteGames';
import { ShopContext, ShopContextProvider } from './context/ShopContextProvider';
import {NextUIProvider, Spinner} from "@nextui-org/react";
import BottomNavigation2 from './navigation/BottomNavigation2.js';
import PlayGame from './screens/PlayGame';
import ShoppingCart from "./screens/ShoppingCart.js";
import MyAccount from './screens/MyAccount';
import HostScreen from './components/GamePlay/HostScreen';
import GuestScreen from './components/GamePlay/GuestScreen';
import MyGames from './components/AccountItems/MyGames';
import Settings from './components/AccountItems/Settings';
import Contact from './components/AccountItems/Contact';
import TermsofUse from './components/AccountItems/TermsofUse';
import GamePlayScreen from './components/GamePlay/GamePlayScreen';
import HeaderComponent from './components/Search/HeaderComponent';
import HostStartGame from './components/GamePlay/HostStartGame';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal, ModalContent, useDisclosure } from "@nextui-org/react";
import { ButikAPI } from './apis/ButikAPI';
import About from './components/AccountItems/About';
import LoadingPage from './components/Helpers/LoadingPage';


function App() {

  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [startPage, setStartPage] = useState();
  const [addsFilter, setAddsFilter] = useState();
  const [imgURL, setImgURL] = useState();
  const [addsData, setAddsData] = useState();
  const [displayAdd, setDisplayAdd] = useState(false);

  const setStartPageTest = (item) => {
    setStartPage(item);
  }

  const fetchAdd = () => {
    ButikAPI.getCurrentAd().then(r => r.json()).then((data) => {
        if(data.status === "success"){
            if(data.ad !== null){
                setImgURL(data.ad.image);
                setAddsData(data.ad.products);
                setDisplayAdd(true);
            }
        }
        else{
          console.log(data);
        }
      })
  }

  useEffect(() => {
    //get add
    fetchAdd();
  },  [])

  const handleClose = () => {
    setDisplayAdd(false);
    setAddsFilter(addsData);
  }

  return (
    <div className="App" >
      <ShopContextProvider>
        <NextUIProvider>
          <div className="container bg-gray-100">
            <Routes>
              <Route path='/pim' element={<AllGames addsFilter={addsFilter}/>} />
              <Route path='/playgame' element={<PlayGame />} />
              <Route path='/favoritegames' element={<FavoriteGames />} />
              <Route path='/shoppingcart' element={<ShoppingCart setStartPage={setStartPageTest}/>} />
              <Route path='/myaccount' element={<MyAccount />} />
              <Route path='/hostscreen' element={<HostScreen />} />
              <Route path='/guestscreen' element={<GuestScreen />} />
              <Route path='/mygames' element={<MyGames goToShop={setStartPageTest}/>}/>
              <Route path='/settings' element={<Settings />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/termsofuse' element={<TermsofUse />} />
              <Route path='/gameplay' element={<GamePlayScreen />} />
              <Route path='/header' element={<HeaderComponent goBack={setStartPageTest}/>} />
              <Route path='/hoststartgame' element={<HostStartGame />} />
              <Route path='/about' element={<About />} />
            </Routes>

            <BottomNavigation2 startPage={startPage}/>
            <LoadingPage />

            {displayAdd ?
                <div style={{zIndex: 90}} className="inset-0 absolute bg-gray-50 flex justify-center items-center">
                    <div className="w-full h-full">
                        <img
                        alt="gameadd"
                        className="w-full object-cover h-full inset-0 absolute"
                        src={"https://www.pimparty.se/" + imgURL}
                        //src={reklamPic}
                        />
                        <div className="absolute bottom-20 left-0 right-0">
                        <div className="grid grid-cols-1 p-8 gap-5">
                            <Button style={{backgroundColor: "#504459"}}
                                className="h-14 text-xl text-white shadow-lg"
                                variant="flat"
                                color="danger"
                                aria-label="Buy"
                                onPress={handleClose}>
                                    Se produkter
                            </Button>
                            <Button style={{color: "#504459"}}
                                className="font-normal italic h-8 text-lg"
                                variant="light"
                                size="lg"
                                aria-label="goshop"
                                onPress={() => setDisplayAdd(false)}>
                                    Gå till butik
                            </Button>
                        </div>
                        </div>
                    </div>
                </div>
            : ""}

          </div>
        </NextUIProvider>
      </ShopContextProvider>
    </div>
  );
}

export default App;
