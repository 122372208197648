import React, { useContext, useEffect, useState } from "react";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Card, CardBody, Image, Button, Popover, PopoverTrigger, PopoverContent, Listbox, ListboxItem, useDisclosure, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Select, SelectItem } from "@nextui-org/react";
import { ShopContext } from "../../../context/ShopContextProvider";
import { useNavigate } from "react-router-dom";

function MyGamesItem(props){
    const {URL_MEDIA} = useContext(ShopContext);
    const navigate = useNavigate();
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [selectedGroup, setSelectedGroup] = useState();
    const [isPopOpen, setIsPopOpen] = useState(false);

    const handleStart = () => {
        navigate("/hostscreen", {state: {data: props.game}});
    }
    const handleGroup = () => {
        setIsPopOpen(false);
        //lägg till spelet i en grupp, finns bara i 'Alla lekar'
        onOpen();

    }
    const handleDelete = () => {
        setIsPopOpen(false);
        //ta bort spelet från en grupp
        // om spelet är i 'alla lekar, inaktiveras deni
        if(props.deleteGame !== null && props.deleteGame !== undefined)
            props.deleteGame(props.game.id_game_group, props.game.id_game);

    }

    const savegroup = () => {
        setIsPopOpen(false);
        //save game to group
        if(props.changeGroup !== null && props.changeGroup !== undefined)
            props.changeGroup(parseInt(selectedGroup.currentKey), props.game.id_game);
        onOpenChange();
    }

    return(
        <div className="pb-3">
            <Card
                isBlurred
                className="border-none bg-background/60 dark:bg-default-100/50 max-w-[610px]"
                shadow="sm">
                <CardBody className="p-3">
                    <div className="grid grid-cols-10">
                        <div className="col-span-3 px-3">
                            <Image
                                alt={props.game.name}
                                className="object-cover"
                                width="100%"
                                height="50"
                                shadow="md"
                                src={props.game.title_picture}
                                />
                        </div>
                        <div className="col-span-6 flex justify-center text-center">
                            <p className="text-tiny uppercase font-bold line-clamp-2">{props.game.name}</p>
                            <small className="text-default-500 line-clamp-2">{props.game.shopDescription}</small>
                        </div>
                        <div className="col-span-1">
                            <Popover
                                placement="left-end"
                                isOpen={isPopOpen}
                                onOpenChange={(open) => setIsPopOpen(open)}>
                                <PopoverTrigger>
                                    <Button
                                        variant="light"
                                        isIconOnly
                                        aria-label="More">
                                            <MoreVertOutlinedIcon />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <div className="grid grid-cols-1 p-0">
                                    {props.game.is_active === 0 ?
                                        <Button radius="none"
                                            size="sm"
                                            variant="light"
                                            onPress={handleDelete}>
                                                Aktivera
                                        </Button>
                                        :
                                        <div className="grid grid-cols-1 p-0">
                                            <Button radius="none"
                                                size="sm"
                                                variant="light"
                                                onPress={handleStart}>
                                                    Starta spel
                                            </Button>
                                            <Button radius="none"
                                                size="sm"
                                                variant="light"
                                                onPress={handleGroup}>
                                                    Lägg till i grupp
                                            </Button>
                                            <Button radius="none"
                                                size="sm"
                                                variant="light"
                                                onPress={handleDelete}>
                                                    {props.isMainGroup ? 'Dölj i listan' : 'Ta bort'}
                                            </Button>
                                        </div>
                                    }
                                    </div>
                                </PopoverContent>
                            </Popover>
                        </div>
                    </div>
                </CardBody>
            </Card>

            <Modal
                placement="center"
                isOpen={isOpen}
                onOpenChange={onOpenChange}>
                <ModalContent>
                {(onClose) => (
                    <>
                    <ModalHeader className="flex flex-col gap-1">
                        Lägg till i grupp
                    </ModalHeader>
                    <ModalBody>
                        <Select
                            label="Välj grupp"
                            variant="bordered"
                            selectedKeys={selectedGroup}
                            className="max-w-xs"
                            onSelectionChange={setSelectedGroup}
                        >
                            {props.groups?.map((group) => (
                            <SelectItem key={group.id_group} value={group.id_group}>
                                {group.name}
                            </SelectItem>
                            ))}
                        </Select>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={onClose}>
                            Avbryt
                        </Button>
                        <Button variant="light" onPress={savegroup}>
                            Spara
                        </Button>
                    </ModalFooter>
                    </>
                )}
                </ModalContent>
            </Modal>
        </div>
    )
}

export default MyGamesItem;
