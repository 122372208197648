import React, { useContext } from "react";
import {Card, CardBody, Image, Button, CardHeader } from "@nextui-org/react";
import { ShopContext } from "../../context/ShopContextProvider";

function CartItem ({game}, {key}){
    const {removeFromCart, URL_MEDIA} = useContext(ShopContext);
    return(
        <Card
            isBlurred
            className="border-none bg-background/60 dark:bg-default-100/50"
            shadow="sm">
           {/*  <CardHeader className="pb-0 pt-2 flex-col items-center">

            </CardHeader> */}
            <CardBody className="p-3">
                    <div className="grid grid-cols-2 gap-6">
                        <div className="grid grid-cols-6  gap-6">
                            <div className="relative col-span-6">
                                <img
                                alt={game?.name}
                                className="object-cover h-28 w-full"
                                shadow="md"
                                src={game?.images[0].url}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-1 ">
                          <p className="text-tiny uppercase font-bold">{game?.name}</p>
                            <small className="text-default-500 line-clamp-2">{game?.shopDescription}</small>
                            <h4 className="font-bold text-large">{game?.price}:-</h4>
                            <Button className="text-black"
                                variant="flat"
                                    color="danger"
                                    aria-label="Buy"
                                    onClick={() => removeFromCart(game)}>
                                        Ta bort
                                    </Button>
                        </div>
                    </div>
            </CardBody>
        </Card>
    );
};

export default CartItem;
